<div *ngIf="loading$ | async; else ViewLoaded" class="loading-view">
  <mat-spinner [diameter]="80" [strokeWidth]="5"></mat-spinner>
</div>

<ng-template #ViewLoaded>
  <div *ngIf="!purchases?.length; else UsageView" class="no-purchases-view">
    <h1 [translate]="'MANAGEMENT.PARTNER.NO_USAGES_OF_CODE'"></h1>
  </div>
</ng-template>

<ng-template #UsageView>
  <div class="table-wrapper">
    <nz-table [nzBordered]="true" [nzData]="purchases" [nzShowPagination]="false" [nzWidthConfig]="">
      <thead>
        <tr>
          <th nzWidth="6%">#</th>
          <th nzWidth="24%">
            {{ 'MANAGEMENT.PARTNER.USAGE_TABLE.BUYER_NAME' | translate }}
          </th>
          <th nzWidth="22%">
            {{ 'MANAGEMENT.PARTNER.USAGE_TABLE.PURCHASE_AMOUNT' | translate }}
          </th>
          <th nzWidth="22%">
            {{ 'MANAGEMENT.PARTNER.USAGE_TABLE.POINTS_QUANTITY' | translate }}
          </th>
          <th nzWidth="24%">
            {{ 'MANAGEMENT.PARTNER.USAGE_TABLE.PURCHASE_DATE' | translate }}
          </th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let purchase of purchases; index as indexOfPurchase">
          <td nzWidth="6%">{{ indexOfPurchase + 1 }}</td>
          <td nzWidth="24%">{{ purchase.user.name }}</td>
          <td nzWidth="22%">{{ purchase.totalAmountPaid / 100 }} zł</td>
          <td nzWidth="22%">
            {{ purchase.partnerPointsAdded[0].quantity }}
          </td>
          <td nzWidth="24%">{{ purchase.updatedAt | date: 'dd-MM-yyyy HH:mm' }}</td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</ng-template>
