<div *ngIf="loadingView; else ViewLoaded" class="loading-view">
  <mat-spinner [diameter]="80" [strokeWidth]="5"></mat-spinner>
</div>

<ng-template #ViewLoaded>
  <div *ngIf="!partnerCode?.id; else PartnerCodeExists" class="no-code-generated-view">
    <h1 [translate]="'MANAGEMENT.PARTNER.NO_PARTNER_CODE_GENERATED'"></h1>
    <button nz-button nzType="primary" (click)="generatePartnerCode()">
      {{ 'MANAGEMENT.PARTNER.GENERATE_CODE' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #PartnerCodeExists>
  <nz-tabset class="partner-account-tabs-in-drawer">
    <nz-tab [nzTitle]="'MANAGEMENT.PARTNER.GENERAL' | translate">
      <ng-template nz-tab>
        <app-account-general-info [userId]="userId" [userName]="userName"></app-account-general-info>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="'MANAGEMENT.PARTNER.CODE_SETTINGS' | translate">
      <ng-template nz-tab>
        <app-partner-code-form [userId]="userId" [userName]="userName"> </app-partner-code-form>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="'MANAGEMENT.PARTNER.USAGE_HISTORY' | translate">
      <ng-template nz-tab>
        <app-code-usage [userId]="userId" [userName]="userName"></app-code-usage>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="'MANAGEMENT.PARTNER.PAYOUTS' | translate">
      <ng-template nz-tab>
        <app-partner-payouts-history [userId]="userId" [userName]="userName"></app-partner-payouts-history>
      </ng-template>
    </nz-tab>
    <nz-tab [nzTitle]="'MANAGEMENT.PARTNER.ALL_POINTS_LIST' | translate">
      <ng-template nz-tab>
        <app-partner-all-points [userId]="userId" [userName]="userName"></app-partner-all-points>
      </ng-template>
    </nz-tab>
  </nz-tabset>
</ng-template>
