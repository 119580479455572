import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { patterns } from '@constants';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { MyPartnerAccountFacade } from '@state/my-partner-account';
import { ReplaySubject, take, takeUntil } from 'rxjs';
import { PayoutRequestModalOpts } from './payout-request-modal.service';

@Component({
  selector: 'app-payout-request-modal',
  templateUrl: './payout-request-modal.component.html',
  styleUrls: ['./payout-request-modal.component.scss'],
})
export class PayoutRequestModalComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  public blockingLoading = false;

  public currentView: 'QUESTION' | 'WRONG_ANSWER' | 'CONFIRM_REQUEST' | 'SUCCESS' | 'ERROR' = 'QUESTION';
  public requestMade = false;

  public transferDataForm = this.fb.group({
    transferDataName: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
        Validators.pattern(patterns.PLNameValidator),
      ]),
    ],
    transferDataSurname: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(1),
        Validators.maxLength(100),
        Validators.pattern(patterns.PLNameValidator),
      ]),
    ],
    transferDataBankAccountNr: [
      '',
      Validators.compose([
        Validators.required,
        Validators.minLength(10),
        Validators.maxLength(100),
        Validators.pattern(patterns.PhoneNumberValidator),
      ]),
    ],
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PayoutRequestModalOpts,
    private readonly dialogRef: MatDialogRef<PayoutRequestModalComponent>,
    private readonly myPartnerAccountFacade: MyPartnerAccountFacade,
    private readonly confirmation: ConfirmationModalService,
    private readonly fb: FormBuilder
  ) {}

  ngOnInit(): void {
    this.showLoading();
    this.hideLoading(700);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public questionAnswered(answer: boolean): void {
    this.showLoading();

    this.currentView = answer ? 'CONFIRM_REQUEST' : 'WRONG_ANSWER';

    this.hideLoading(700);
  }

  public tryAgain(): void {
    this.showLoading();
    this.currentView = 'QUESTION';
    this.hideLoading(700);
  }

  public requestPayout(): void {
    if (this.transferDataForm.invalid) {
      return;
    }

    this.showLoading();

    this.myPartnerAccountFacade.requestPayoutSuccess$
      .pipe(take(1), takeUntil(this.myPartnerAccountFacade.requestPayoutError$), takeUntil(this.destroy$))
      .subscribe(() => {
        this.requestMade = true;
        this.currentView = 'SUCCESS';
        this.hideLoading(600);
      });

    this.myPartnerAccountFacade.requestPayoutError$
      .pipe(take(1), takeUntil(this.myPartnerAccountFacade.requestPayoutSuccess$), takeUntil(this.destroy$))
      .subscribe(() => {
        this.requestMade = false;
        this.currentView = 'ERROR';
        this.hideLoading(600);
      });

    const opts = this.transferDataForm.getRawValue();
    this.myPartnerAccountFacade.requestPayout(opts);
  }

  public closeDialog(): void {
    this.dialogRef.close(this.requestMade);
  }

  private showLoading(): void {
    this.blockingLoading = true;
  }
  private hideLoading(delay: number = 350): void {
    setTimeout(() => {
      this.blockingLoading = false;
    }, delay);
  }
}
