import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

import { MyPartnerAccountHttpService } from '@core/http/my-partner-account.http.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import * as actions from './my-partner-account.actions';

@Injectable()
export class MyPartnerAccountEffects {
  constructor(
    private actions$: Actions,
    private http: MyPartnerAccountHttpService,
    private snackService: SnackbarService
  ) {}

  getMyPartnerAccountDetails$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getMyPartnerAccountDetails),
      switchMap(() => {
        return this.http.getMyPartnerAccountDetails().pipe(
          map(discountCode => actions.getMyPartnerAccountDetailsSuccess({ discountCode })),
          catchError(() => {
            this.snackService.showError('PARTNER_ACCOUNT.ERROR_FETCHING_ACCOUNT_DETAILS');
            return of(actions.getMyPartnerAccountDetailsError());
          })
        );
      })
    )
  );

  getMyPontsSum$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getMyPointsSum),
      switchMap(() => {
        return this.http.getMyPartnerPoints().pipe(
          map(sumOfPoints => actions.getMyPointsSumSuccess({ sumOfPoints })),
          catchError(() => {
            this.snackService.showError('PARTNER_ACCOUNT.ERROR_FETCHING_PARTNER_POINTS_SUM');
            return of(actions.getMyPointsError());
          })
        );
      })
    )
  );

  isPayoutAvailable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.isPayoutAvailable),
      switchMap(() => {
        return this.http.payoutAvailable().pipe(
          map(payoutAvailable => actions.isPayoutAvailableSuccess({ payoutAvailable })),
          catchError(() => {
            this.snackService.showError('PARTNER_ACCOUNT.ERROR_CHECKING_IS_PAYOUT_AVAILABLE');
            return of(actions.isPayoutAvailableError());
          })
        );
      })
    )
  );

  requestPayout$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.requestPayout),
      switchMap(({ opts }) => {
        return this.http.requestPayout(opts).pipe(
          map(partnerPointsPayout => actions.requestPayoutSuccess({ partnerPointsPayout })),
          catchError(() => {
            this.snackService.showError('PARTNER_ACCOUNT.ERROR_REQUESTING_PAYOUT');
            return of(actions.requestPayoutError());
          })
        );
      })
    )
  );

  getMyPayoutsHistory$ = createEffect(() =>
    this.actions$.pipe(
      ofType(actions.getMyPayoutsHistory),
      switchMap(() => {
        return this.http.getMyPayoutsHistory().pipe(
          map(payoutsHistory => actions.getMyPayoutsHistorySuccess({ payoutsHistory })),
          catchError(() => {
            this.snackService.showError('PARTNER_ACCOUNT.ERROR_REQUESTING_PAYOUTS_HISTORY');
            return of(actions.getMyPayoutsHistoryError());
          })
        );
      })
    )
  );
}
