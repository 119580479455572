<div *ngIf="loading$ | async; else ViewLoaded" class="loading-view">
  <mat-spinner [diameter]="80" [strokeWidth]="5"></mat-spinner>
</div>

<ng-template #ViewLoaded>
  <div *ngIf="!!(payouts$ | async)?.length === false; else PayoutsView" class="no-payouts-view">
    <h1 [translate]="'MANAGEMENT.PARTNER.NO_PAYOUTS'"></h1>
  </div>
</ng-template>

<ng-template #PayoutsView>
  <div class="table-wrapper">
    <nz-table [nzBordered]="true" [nzData]="payouts$ | async" [nzShowPagination]="false" [nzWidthConfig]="">
      <thead>
        <tr>
          <th nzWidth="6%">#</th>
          <th nzWidth="24%">
            {{ 'MANAGEMENT.PARTNER.PAYOUTS_TABLE.REQUESTED_AT' | translate }}
          </th>
          <th nzWidth="22%">
            {{ 'MANAGEMENT.PARTNER.PAYOUTS_TABLE.POINTS_IN_PAYOUT' | translate }}
          </th>
          <th nzWidth="22%">
            {{ 'MANAGEMENT.PARTNER.PAYOUTS_TABLE.STATUS' | translate }}
          </th>
          <th nzWidth="24%"></th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="let payout of payouts$ | async; index as indexOfPayout"
          [class.payout-requested]="payout.isPending"
          [class.paid-out]="payout.isPaidOut">
          <td nzWidth="6%">{{ indexOfPayout + 1 }}</td>
          <td nzWidth="22%">{{ payout.requestedAt | date: 'dd-MM-yyyy' }}</td>
          <td nzWidth="22%">{{ payout.totalPointsInPayout }}</td>
          <td nzWidth="24%" [class.is-done]="payout.isPaidOut" [class.is-important]="payout.isPending">
            {{ 'MANAGEMENT.PARTNER.PARTNER_POINTS_PAYOUT_STATUS.' + payout.status | translate }}
          </td>
          <td nzWidth="24%">
            <button
              *ngIf="payout.isPending"
              (click)="confirmPayout(payout)"
              class="approve-button"
              nz-button
              nzType="primary"
              nzShape="round">
              <span nz-icon nzType="check-circle" nzTheme="outline"></span>
            </button>
            <button
              *ngIf="payout.isCancellable"
              (click)="cancelPayout(payout)"
              class="cancel-button"
              nz-button
              nzType="primary"
              nzShape="round">
              <span nz-icon nzType="close-circle" nzTheme="outline"></span>
            </button>
            <button
              *ngIf="payout.isRevertable"
              (click)="revertPayout(payout)"
              class="revert-button"
              nz-button
              nzType="primary"
              nzShape="round">
              <span nz-icon nzType="rollback" nzTheme="outline"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</ng-template>
