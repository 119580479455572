import { APP_DETAILS_TAB } from '@constants';
import {
  ChangeRoutePayload,
  DocumentsSigningMode,
  InAppNotification,
  InAppNotificationType,
  ROLES_KEYS,
  User,
  UserProcessStatus,
} from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import * as moment from 'moment-timezone';

const foreignerSeeSummaryWithStatuses = [
  UserProcessStatus.REVIEWED_READY_TO_PRINT,
  UserProcessStatus.REVIEWED_READY_TO_PRINT_WAITING_FOR_DOCUMENTS_SIGNATURE,
  UserProcessStatus.APPROVED,
  UserProcessStatus.OFFICE_VISIT_AVAILABILITY_PROVIDED,
  UserProcessStatus.OFFICE_VISIT_SCHEDULED,
  UserProcessStatus.DOCUMENTS_SIGNED_WAITING_FOR_VOIVO_OFFICE_VISIT_DATE,
  UserProcessStatus.DECISION_ISSUED,
  UserProcessStatus.APPLICATION_SUBMITTED,
];

export const setNotificationViewOpts = (
  notification: InAppNotification,
  user: User,
  translateService: TranslateService
): InAppNotification => {
  const isForeigner = user.role.key === ROLES_KEYS.Foreigner;
  const foreignerName = getForeignerName(notification);
  const { signingMode, signingDate } = getDocsSigningDataFormatted(notification, translateService);
  const clickable = isForeigner ? canForeignerClickNotification(notification) : true;
  const linkParamsFunction = notificationTypeToLinkParams[notification.type];
  const linkParams = clickable && linkParamsFunction ? linkParamsFunction(notification) : null;
  let processCategoryTranslated = null;
  if (notification.data.additionalInfo?.processCategory) {
    processCategoryTranslated = translateService.instant(
      'NOTIFICATION_PROCESS_CATEGORY.' + notification.data.additionalInfo.processCategory
    );
  }

  let documentName = '';
  if (notification.relatedUserDocument) {
    documentName = getDocumentName(notification, translateService);
  }

  if (!linkParamsFunction) {
    console.warn(`Missing notification configurator for ${notification.type}`);
  }

  let origError = '';
  if (notification.data.additionalInfo?.origError) {
    origError = notification.data.additionalInfo?.origError;
  }

  if (!origError && notification.data.additionalInfo.error) {
    origError = notification.data.additionalInfo.error;
  }

  return {
    ...notification,
    foreignerName,
    documentName,
    clickable,
    linkParams,
    origError,
    data: {
      ...notification.data,
      additionalInfo: {
        ...(notification.data?.additionalInfo || {}),
        signingDate,
        signingMode,
        processCategoryTranslated,
      },
    },
  };
};

const canForeignerClickNotification = (notification: InAppNotification): boolean => {
  if (!notification?.relatedUserProcess?.status) {
    return true;
  }

  const userProcessStatus = notification.relatedUserProcess.status;
  const userSeeSummaryInProcess = foreignerSeeSummaryWithStatuses.includes(userProcessStatus);

  if (userSeeSummaryInProcess) {
    const unclickableNotificationTypes = [
      InAppNotificationType.EMPLOYEE_ADDED_DOCUMENT_COMMENT,
      InAppNotificationType.DOCUMENTS_LIST_CHANGED,
    ];
    return !unclickableNotificationTypes.includes(notification.type);
  }

  if (!userSeeSummaryInProcess) {
    const unclickableNotificationTypes = [
      InAppNotificationType.NEW_FINAL_VERIFICATION_COMMENTS,
      InAppNotificationType.CHANGED_FINAL_VERIFICATION_COMMENTS,
    ];
    return !unclickableNotificationTypes.includes(notification.type);
  }

  return true;
};

export const getDocumentName = (notification: InAppNotification, translateService: TranslateService): string => {
  const doc = notification.relatedUserDocument?.document;
  if (!doc?.name[translateService.currentLang]) {
    return '';
  }
  return truncateText(doc.name[translateService.currentLang], 50);
};

export const getDocsSigningDataFormatted = (
  notification: InAppNotification,
  translateService: TranslateService
): { signingMode: string; signingDate: string } => {
  const opts: { signingMode: string; signingDate: string } = {
    signingMode: null,
    signingDate: null,
  };

  const sMode = notification.data?.additionalInfo?.newMode;
  if (sMode) {
    opts.signingMode = translateService.instant(`NT.MODE_${sMode}`);
  }
  if (notification.data?.additionalInfo?.newDate) {
    opts.signingDate = moment(notification.data.additionalInfo.newDate).format('DD-MM-YYYY HH:mm');
  }
  if (
    !notification.data?.additionalInfo?.newDate &&
    notification.data?.additionalInfo?.oldDate &&
    sMode === DocumentsSigningMode.SENDING_BY_POST
  ) {
    // used to display info: 'visit from date {{ signingDate }} was cancelled'
    // so we have to use oldDate because newDate is null
    opts.signingDate = moment(notification.data.additionalInfo.oldDate).format('DD-MM-YYYY HH:mm');
  }
  return opts;
};

export const getForeignerName = (notification: InAppNotification): string => {
  let foreignerName = notification.data?.additionalInfo?.foreignerName;

  if (notification.relatedForeigner) {
    foreignerName = notification.relatedForeigner.name || notification.relatedForeigner.email;
  }

  if (notification.relatedUserProcess?.personalDetails) {
    const { personalDetails } = notification.relatedUserProcess;
    if (personalDetails.name) {
      foreignerName = personalDetails.name;
      if (personalDetails.surname) {
        foreignerName = `${foreignerName} ${personalDetails.surname}`;
      }
    }
  }
  const name = foreignerName || '';
  return truncateText(name, 20, false, '...');
};

const notificationTypeToLinkParams: NotificationTypeToLinkParams = {
  [InAppNotificationType.APPLICATION_STATUS_CHANGED]: (notification: InAppNotification) => null,
  [InAppNotificationType.APPLICATION_STATUS_CHANGED_WITH_TYPE]: (notification: InAppNotification) => null,
  [InAppNotificationType.DOCUMENTS_GENERATION_ERROR]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),
  [InAppNotificationType.DOCUMENTS_GENERATION_SUCCESS]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),
  [InAppNotificationType.DOCUMENTS_SENT_TO_EXTERNAL_SYSTEM_AND_GENERATED]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),
  [InAppNotificationType.DOCUMENTS_LIST_CHANGED]: (notification: InAppNotification) => null,
  [InAppNotificationType.EMPLOYEE_ADDED_DOCUMENT_COMMENT]: (notification: InAppNotification) => ({
    linkParams: ['user-process', notification.relatedUserProcessId, 'documents'],
    extras: {
      queryParams: {
        revealDocumentId: notification.relatedUserDocumentId,
        openChatForUserDocumentId: notification.relatedUserDocumentId,
      },
    },
  }),
  [InAppNotificationType.EMPLOYEE_CANCELLED_OFFICE_APPOINTMENT]: (notification: InAppNotification) => null,
  [InAppNotificationType.APPLICATION_MODE_CHANGED]: (notification: InAppNotification) => ({
    linkParams: ['user-process', notification.relatedUserProcessId],
  }),

  // [InAppNotificationType.EMPLOYEE_CHANGED_DOCUMENT_STATUS]: (notification: InAppNotification) => ({
  //   linkParams: ['user-process', notification.relatedUserProcessId, 'documents'],
  //   extras: { queryParams: { revealDocumentId: notification.relatedUserDocumentId } },
  // }),
  [InAppNotificationType.EMPLOYEE_SENT_MESSAGE_IN_CHAT]: (notification: InAppNotification) => null,
  [InAppNotificationType.FOREIGNER_ADDED_DOCUMENT_COMMENT]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: {
        appDetailsTab: APP_DETAILS_TAB.DOCS,
        revealUserDocumentId: notification.relatedUserDocumentId,
        openChatForUserDocumentId: notification.relatedUserDocumentId,
      },
    },
  }),
  [InAppNotificationType.FOREIGNER_CHANGED_PERSONAL_DETAILS]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: { queryParams: { appDetailsTab: APP_DETAILS_TAB.GENERAL } },
  }),
  [InAppNotificationType.FOREIGNER_HAS_BEEN_ASSIGNED_TO_YOU]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.data.additionalInfo?.activeProcess?.id,
    ],
    extras: { queryParams: { appDetailsTab: APP_DETAILS_TAB.GENERAL } },
  }),
  [InAppNotificationType.FOREIGNER_SENT_PRE_SURVEY_FOR_VERIFICATION]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: { queryParams: { appDetailsTab: APP_DETAILS_TAB.PRE_SURVEY } },
  }),
  [InAppNotificationType.FOREIGNER_SENT_APPLICATION_TO_VERIFICATION]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: { queryParams: { appDetailsTab: APP_DETAILS_TAB.GENERAL } },
  }),
  [InAppNotificationType.FOREIGNER_SENT_MESSAGE_IN_CHAT]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
  }),
  [InAppNotificationType.FOREIGNER_SET_AVAILABILITY]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: { queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY } },
  }),
  [InAppNotificationType.FOREIGNER_SENDS_APPLICATION_WITH_POST]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: { queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY } },
  }),

  [InAppNotificationType.INPOL_SENDING_ERROR]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),
  [InAppNotificationType.INPOL_SENDING_SUCCESS]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),

  [InAppNotificationType.MOS_SENDING_ERROR]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),
  [InAppNotificationType.MOS_SENDING_SUCCESS]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),

  [InAppNotificationType.FOREIGNER_APPLICATION_MODE_HAS_CHANGED]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),
  [InAppNotificationType.FOREIGNER_PICKED_SIGNING_MODE]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),
  [InAppNotificationType.FOREIGNER_CHANGED_PLANNED_DOCUMENTS_SIGNING_MODE]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),
  [InAppNotificationType.FOREIGNER_CHANGED_PLANNED_DOCUMENTS_SIGNING_DATE]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),
  // Notification displayed to all employees assigned to the office
  [InAppNotificationType.FOREIGNER_PLANNING_TO_COME_TO_THE_OFFICE]: (notification: InAppNotification) => ({
    linkParams: ['management', 'visit-calendar'],
    extras: {
      queryParams: { visibleDate: notification.data?.additionalInfo?.newDate || '' },
    },
  }),
  // Notification displayed to all employees assigned to the office
  [InAppNotificationType.FOREIGNER_CHANGED_DATE_OF_COMPANY_OFFICE_VISIT]: (notification: InAppNotification) => ({
    linkParams: ['management', 'visit-calendar'],
    extras: {
      queryParams: { visibleDate: notification.data?.additionalInfo?.newDate || '' },
    },
  }),
  // Notification displayed to all employees assigned to the office
  [InAppNotificationType.FOREIGNER_CANCELLED_VISIT_IN_COMPANY_OFFICE]: (notification: InAppNotification) => null,
  [InAppNotificationType.FOREIGNER_CONFIRMED_READINESS_FOR_VISIT]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),
  // Notification displayed to single employee
  [InAppNotificationType.FOREIGNER_PLANNING_TO_COME_TO_OTHER_OFFICE]: (notification: InAppNotification) => ({
    linkParams: ['management', 'visit-calendar'],
    extras: {
      queryParams: { visibleDate: notification.data?.additionalInfo?.newDate || '' },
    },
  }),
  // Notification displayed to single employee
  [InAppNotificationType.FOREIGNER_CHANGED_DATE_OF_OTHER_COMPANY_OFFICE_VISIT]: (notification: InAppNotification) => ({
    linkParams: ['management', 'visit-calendar'],
    extras: {
      queryParams: { visibleDate: notification.data?.additionalInfo?.newDate || '' },
    },
  }),
  // Notification displayed to single employee
  [InAppNotificationType.FOREIGNER_CANCELLED_VISIT_IN_OTHER_COMPANY_OFFICE]: (notification: InAppNotification) => null,
  [InAppNotificationType.FOREIGNER_CANCELLED_READINESS_FOR_VISIT]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),
  [InAppNotificationType.FOREIGNER_ADDED_ITEMS_TO_REVIEW]: (notification: InAppNotification) => ({
    linkParams: [
      'management',
      'application-details',
      notification.relatedForeignerId,
      notification.relatedUserProcessId,
    ],
    extras: {
      queryParams: { appDetailsTab: APP_DETAILS_TAB.SUMMARY },
    },
  }),

  [InAppNotificationType.FOREIGNER_SET_IMMIGRATION_OFFICE_VISIT_DATE]: (notification: InAppNotification) => ({
    linkParams: ['management', 'visit-calendar'],
    extras: {
      queryParams: { visibleDate: notification.data?.additionalInfo?.visitDate || '' },
    },
  }),
  [InAppNotificationType.FOREIGNER_CHANGED_IMMIGRATION_OFFICE_VISIT_DATE]: (notification: InAppNotification) => ({
    linkParams: ['management', 'visit-calendar'],
    extras: {
      queryParams: { visibleDate: notification.data?.additionalInfo?.visitDate || '' },
    },
  }),
  [InAppNotificationType.NEW_FINAL_VERIFICATION_COMMENTS]: (notification: InAppNotification) => ({
    linkParams: ['user-process', notification.relatedUserProcessId, 'summary'],
    extras: {
      queryParams: {
        showDocumentsVerificationComment: true,
      },
    },
  }),
  [InAppNotificationType.CHANGED_FINAL_VERIFICATION_COMMENTS]: (notification: InAppNotification) => ({
    linkParams: ['user-process', notification.relatedUserProcessId, 'summary'],
    extras: {
      queryParams: {
        showDocumentsVerificationComment: true,
      },
    },
  }),
  [InAppNotificationType.REASSIGNED_FOREIGNERS_TO_YOU]: (notification: InAppNotification) => ({
    linkParams: ['management', 'applications-list'],
    extras: {
      queryParams: {
        creatorId: notification.recipientId,
      },
    },
  }),
  [InAppNotificationType.NEW_APPLICATION_HAS_BEEN_ADDED]: (notification: InAppNotification) => null,
  [InAppNotificationType.NEW_APPLICATION_HAS_BEEN_ADDED_WITH_TYPE]: (notification: InAppNotification) => null,
  [InAppNotificationType.APPLICATION_HAS_BEEN_DELETED]: (notification: InAppNotification) => null,
  [InAppNotificationType.PARTNER_REQUESTED_PAYOUT]: (notification: InAppNotification) => ({
    linkParams: ['partners', 'payouts'],
  }),
};

interface NotificationTypeToLinkParams {
  [key: string]: (notification: InAppNotification) => ChangeRoutePayload | null;
}

const truncateText = (text: string, limit = 25, completeWords = false, ellipsis = '...'): string => {
  if (completeWords) {
    limit = text.substr(0, limit).lastIndexOf(' ');
  }
  return text.length > limit ? text.substr(0, limit) + ellipsis : text;
};
