import { Pagination, PartnerPoints, User } from '@interfaces';

export interface RequestPayoutOpts {
  transferDataName: string;
  transferDataSurname: string;
  transferDataBankAccountNr: string;
}

export interface TransferData {
  name: string;
  surname: string;
  bankAccountNr: string;
}

export interface CancelPayoutOpts {
  userId: string;
  payoutId: string;
}

export interface ChangePayoutStatusOpts {
  userId: string;
  payoutId: string;
  status: PARTNER_POINTS_PAYOUT_STATUS;
}

export enum PARTNER_POINTS_PAYOUT_STATUS {
  REQUESTED = 'REQUESTED',
  PAID_OUT = 'PAID_OUT',
}

export interface PartnerPointsPayout {
  id: string;
  status: PARTNER_POINTS_PAYOUT_STATUS;
  partner?: User;
  partnerId: string;
  pointsInPayout: PartnerPoints[];
  requestedAt: Date;
  paidOutAt: Date;
  updatedAt: Date;
  transferDataAnonymised?: TransferData;

  totalPointsInPayout?: number;
}

export interface PayoutsInView extends PartnerPointsPayout {
  isCancellable: boolean;
  isRevertable: boolean;
  isPaidOut: boolean;
  isPending: boolean;
}

export interface SearchPayoutsOpts extends Pagination {
  status?: PARTNER_POINTS_PAYOUT_STATUS;
}
