import { Clipboard } from '@angular/cdk/clipboard';
import { Component, Input, OnInit } from '@angular/core';
import { PARTNER_POINTS_STATUS } from '@interfaces';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { PartnerCodesFacade } from '@state/partner-codes';

@Component({
  selector: 'app-account-general-info',
  templateUrl: './account-general-info.component.html',
  styleUrls: ['./account-general-info.component.scss'],
})
export class AccountGeneralInfoComponent implements OnInit {
  @Input() userId: string;
  @Input() userName: string;

  public PARTNER_POINTS_STATUS = PARTNER_POINTS_STATUS;
  public allStatusesOfPartnerPoints = Object.values(PARTNER_POINTS_STATUS);

  public loading$ = this.partnerCodesFacade.loading$;
  public generationInProgress$ = this.partnerCodesFacade.codeGenerationInProgress$;
  public partnerPointsSum$ = this.partnerCodesFacade.partnerPointsSum$;
  public partnerCode$ = this.partnerCodesFacade.partnerCodeDetails$;

  constructor(
    private readonly partnerCodesFacade: PartnerCodesFacade,
    private readonly clipboard: Clipboard,
    private readonly snackService: SnackbarService
  ) {}

  ngOnInit(): void {
    this.partnerCodesFacade.getPartnerCodeDetails(this.userId);
    this.partnerCodesFacade.getPartnerPointsSum(this.userId);
  }

  public copyToClipboard(text: string): void {
    if (!text?.length || !text.trim().length) {
      this.snackService.showError('COMMON.CANNOT_COPY_EMPTY_TEXT_TO_CLIPBOARD');
      return;
    }

    const success = this.clipboard.copy(text);
    let trimmedText = text.trim();
    if (trimmedText.length > 20) {
      trimmedText = `${text.slice(0, 20)}...`;
    }

    if (success) {
      this.snackService.showInfo('COMMON.COPIED_TO_CLIPBOARD', { text: trimmedText });
    }
    if (!success) {
      this.snackService.showError('COMMON.UNABLE_TO_COPY_TO_CLIPBOARD', { text: trimmedText });
    }
  }
}
