import {
  MultiLangField,
  Pagination,
  PARTNER_POINTS_SOURCE,
  PARTNER_POINTS_STATUS,
  PartnerPointsPayout,
} from '@interfaces';
import { Purchase } from './purchase';
import { User } from './user-auth';

export enum DISCOUNT_CODE_TYPE {
  'PERCENTAGE' = 'PERCENTAGE',
  'AMOUNT' = 'AMOUNT',
}

export enum PARTNER_TERMS_CONFIG {
  'GLOBAL' = 'GLOBAL',
  'CUSTOM' = 'CUSTOM',
}

export interface DiscountCode {
  id: string;
  isActive: boolean;
  code: string;
  hasDateConstraints: boolean;
  startDate: Date;
  endDate: Date;
  hasUsageConstraints: boolean;
  usageLimit: number;
  discountType: DISCOUNT_CODE_TYPE;
  discountAmount: number; // always in GROSZ PLN
  discountPercentage: number; // 0 - 99

  partner?: User;
  partnerId?: string;
  partnerTermsConfig?: PARTNER_TERMS_CONFIG;
  globalTerms?: PartnerGlobalTerms;
  customTerms_pointsRatio?: number;
  customTerms_slogan?: MultiLangField;

  usedInPurchases: Purchase[]; // Those are unconfirmed usages -> should not be used
  usedInPurchasesConfirmed: Purchase[]; // Those are usages confirmed by transaction verification

  createdByUser: User;
  createdByUserId: string;

  createdAt: Date;
  updatedAt: Date;
}

export class PartnerPoints {
  id: string;
  quantity: number;
  status: PARTNER_POINTS_STATUS;
  partner?: User;
  partnerId: string;

  // null if added manually
  fromPurchase?: Purchase;
  // null if added manually
  fromPurchaseId: string;
  source: PARTNER_POINTS_SOURCE;

  payout?: PartnerPointsPayout;
  payoutId?: string;

  // null if from purchase, filled when added manually
  addedByUser: User;
  // null if from purchase, filled when added manually
  addedByUserId: string;
  inPendingVerificationSince: Date;
  grantedAt: Date;
  inPendingPayoutSince: Date;
  paidOutAt: Date;
  createdAt: Date;
  updatedAt: Date;
}

export interface CreateDiscountCodeOpts {
  isActive: boolean;
  code: string;
  hasDateConstraints: boolean;
  startDate?: Date;
  endDate?: Date;
  hasUsageConstraints: boolean;
  usageLimit?: number;
  discountType: DISCOUNT_CODE_TYPE;
  discountAmount?: number;
  discountPercentage?: number;
}

export interface EditDiscountCodeOpts {
  id: string;
  isActive: boolean;
  hasDateConstraints: boolean;
  hasUsageConstraints: boolean;
  startDate?: string;
  endDate?: string;
  usageLimit?: number;
}

export interface ValidatedDiscountCode {
  id: string;
  discountType: DISCOUNT_CODE_TYPE;
  discountAmount: number;
  discountPercentage: string;
}

export interface SearchDiscountCodesOpts extends Pagination {
  isActive?: boolean;
  query?: string;
  type?: DISCOUNT_CODE_TYPE;
}

// EditPartnerCodeOpts
export interface EditPartnerCodeOpts {
  userId: string;
  discountType: DISCOUNT_CODE_TYPE;
  partnerTermsConfig: PARTNER_TERMS_CONFIG;
  discountAmount?: number;
  discountPercentage?: number;
  customTerms_pointsRatio?: number;
  customTerms_slogan?: MultiLangField;
}
//

export interface PartnerGlobalTerms {
  id: string;
  discountType: DISCOUNT_CODE_TYPE;
  discountAmount: number; // always in GROSZ PLN
  discountPercentage: number; // 0 - 99
  pointsRatio: number;
  slogan: MultiLangField;
  updatedAt: Date;
}
