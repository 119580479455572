import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MyPartnerAccountState } from './my-partner-account.state';

const selectState = createFeatureSelector<MyPartnerAccountState>('myPartnerAccount');

export const selectLoading = createSelector(selectState, state => state.loading);
export const selectPartnerCodeDetails = createSelector(selectState, state => state.partnerCodeDetails);

export const selectSumOfPartnerPoints = createSelector(selectState, state => state.sumOfPartnerPoints);
export const selectPayoutAvailable = createSelector(selectState, state => state.payoutAvailable);
export const selectPayoutsHistory = createSelector(selectState, state => state.payoutsHistory);
