export const environment = {
  production: false,
  API_URL: 'https://kartapobytu.dev/carta-api',
  SOCKET_URL: 'https://kartapobytu.dev',
  version: require('../../package.json').version + '.dev',
  PAGE_TITLE: 'Karta Pobytu - Dev',

  // Times in seconds
  authAnimationTime: 2, // Loading Animation on Login Success
  minLoadingDataTime: 0.3, // Minimal time to load data, prevents immediate disappearance of the loading spinner etc.

  APP_NAME: 'Karta Pobytu - Dev',
  APP_URL: 'https://kartapobytu.dev/',

  GOOGLE_ANALYTICS_ENABLED: true,
  GOOGLE_ANALYTICS_GTAG: 'G-95BMVX23CS',

  GOOGLE_TAG_ENABLED: false,
  GOOGLE_TAG_CONTAINER_ID: '',

  ENABLED_PAYMENT_METHODS_GROUPS: [
    'fasttransfers',
    'blik',
    'wallet',
    'credit card',
    'google pay',
    'apple pay',
    'Installments',
  ],
};
