<div class="code-details-view" *ngIf="partnerCode?.code && partnerCodeForm?.controls">
  <div class="code-header">
    <h1 class="code">{{ partnerCode.code }}</h1>
    <!-- <button
      [disabled]="disablingLoading$ | async"
      (click)="regenerateCode()"
      nz-button
      nzType="default"
      nzSize="large"
      nzShape="circle">
      <span [nzSpin]="disablingLoading$ | async" nz-icon nzType="sync" nzTheme="outline"></span>
    </button> -->
  </div>

  <div class="code-form-wrapper">
    <div class="item">
      <div class="label">
        {{ 'MANAGEMENT.PARTNER.PARTNER_TERMS' | translate }}
      </div>
      <div class="value">
        <nz-switch
          [ngModel]="usingCustomTermsConfig"
          [nzControl]="true"
          (click)="$event.preventDefault(); $event.stopPropagation(); togglePartnerTerms()">
        </nz-switch>
      </div>
    </div>
    <form nz-form [formGroup]="partnerCodeForm">
      <nz-form-item>
        <nz-form-label [nzSm]="12" [nzXs]="24" nzRequired nzFor="discountType">
          {{ 'MANAGEMENT.PARTNER.DISCOUNT_TYPE' | translate }}
        </nz-form-label>
        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="errorTpl">
          <nz-select
            id="discountType"
            formControlName="discountType"
            [nzPlaceHolder]="'MANAGEMENT.PARTNER.SELECT_DISCOUNT_TYPE' | translate"
            [required]="true">
            <nz-option [nzValue]="DISCOUNT_CODE_TYPE.AMOUNT" [nzLabel]="DISCOUNT_CODE_TYPE.AMOUNT"></nz-option>
            <nz-option [nzValue]="DISCOUNT_CODE_TYPE.PERCENTAGE" [nzLabel]="DISCOUNT_CODE_TYPE.PERCENTAGE"> </nz-option>
          </nz-select>
        </nz-form-control>
      </nz-form-item>

      <ng-container *ngIf="partnerCodeForm.controls.discountType.value === DISCOUNT_CODE_TYPE.AMOUNT">
        <nz-form-item>
          <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="discountAmount">
            {{ 'MANAGEMENT.PARTNER.DISCOUNT_AMOUNT' | translate }}
            &nbsp;
            <span nz-icon nz-tooltip nzTooltipTitle="Wyrażona w Złotych Polskich." nzType="info-circle"></span>
            &nbsp;
          </nz-form-label>

          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="errorTpl">
            <nz-input-group [nzSuffix]="suffixTemplateInfo">
              <input
                nz-input
                id="discountAmount"
                [max]="9999.99"
                [min]="1"
                formControlName="discountAmount"
                type="number" />
            </nz-input-group>
            <ng-template #suffixTemplateInfo>
              <span>zł</span>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <ng-container *ngIf="partnerCodeForm.controls.discountType.value === DISCOUNT_CODE_TYPE.PERCENTAGE">
        <nz-form-item>
          <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="discountPercentage">
            {{ 'MANAGEMENT.PARTNER.DISCOUNT_PERCENTAGE' | translate }}
          </nz-form-label>
          <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="errorTpl">
            <input nz-input id="discountPercentage" formControlName="discountPercentage" type="number" />
          </nz-form-control>
        </nz-form-item>
      </ng-container>

      <nz-form-item>
        <nz-form-label [nzSm]="12" [nzXs]="24" nzFor="pointsRatio">
          {{ 'MANAGEMENT.PARTNER.POINTS_RATIO' | translate }}
          &nbsp;
          <span
            nz-icon
            nz-tooltip
            nzTooltipTitle="Ile punktów daje partnerowi każda złotówka wydana w zamówieniu wykorzystującym ten kod"
            nzType="info-circle"></span>
          &nbsp;
        </nz-form-label>

        <nz-form-control [nzSm]="12" [nzXs]="24" [nzErrorTip]="errorTpl">
          <nz-input-group [nzSuffix]="pointsRatioInputSuffix">
            <input
              nz-input
              id="pointsRatio"
              [max]="100"
              [min]="0.01"
              formControlName="customTerms_pointsRatio"
              type="number" />
          </nz-input-group>
          <ng-template #pointsRatioInputSuffix>
            <span>pkt. za 1 zł</span>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-divider nzText="Slogan" nzOrientation="left"></nz-divider>
      <div class="slogans-container" formGroupName="customTerms_slogan">
        <nz-form-item *ngFor="let lang of SUPPORTED_LANGS">
          <nz-form-label [nzSm]="6" [nzXs]="6" nzFor="slangInLang{{ lang }}">
            {{ lang.toUpperCase() }}
          </nz-form-label>

          <nz-form-control [nzSm]="18" [nzXs]="18" [nzErrorTip]="errorTpl">
            <textarea rows="4" nz-input [formControlName]="lang"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </form>
  </div>

  <div class="floating-footer-buttons">
    <button
      class="cancel-button"
      (click)="cancel()"
      [disabled]="disablingLoading$ | async"
      nz-button
      nzSize="large"
      nzType="default">
      {{ 'MANAGEMENT.PARTNER.CANCEL' | translate }}
    </button>
    <button
      class="save-button"
      (click)="save()"
      [disabled]="(disablingLoading$ | async) || partnerCodeForm.invalid || partnerCodeForm.pristine"
      nz-button
      nzSize="large"
      nzType="primary">
      <span *ngIf="(disablingLoading$ | async) === false"> {{ 'MANAGEMENT.PARTNER.SAVE' | translate }}</span>
      <mat-spinner *ngIf="disablingLoading$ | async" [diameter]="35" [strokeWidth]="3"></mat-spinner>
    </button>
  </div>
</div>

<ng-template #errorTpl let-control>
  <ng-container *ngIf="control.hasError('required')">
    {{ 'FORM-VALIDATION.FIELD_IS_REQUIRED' | translate }}
  </ng-container>
  <ng-container *ngIf="control.hasError('min')">
    {{ 'ADMINISTRATION.DC.VALUE_TOO_SMALL' | translate }}
  </ng-container>
  <ng-container *ngIf="control.hasError('minlength')">
    {{ 'ADMINISTRATION.DC.TOO_SHORT' | translate }}
  </ng-container>
  <ng-container *ngIf="control.hasError('max')">
    {{ 'ADMINISTRATION.DC.VALUE_TOO_BIG' | translate }}
  </ng-container>
  <ng-container *ngIf="control.hasError('maxlength')">
    {{ 'ADMINISTRATION.DC.TOO_LONG' | translate }}
  </ng-container>
  <ng-container *ngIf="control.hasError('pattern')">
    {{ 'ADMINISTRATION.DC.FIELD_INCORRECT' | translate }}
  </ng-container>
</ng-template>
