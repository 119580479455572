import { Directive, Input, TemplateRef, ViewContainerRef } from '@angular/core';
import { PermissionEnum, User } from '@interfaces';
import { UserAuthFacade } from '@state/user-auth';
import { firstValueFrom } from 'rxjs';
import { filter } from 'rxjs/operators';

@Directive({
  selector: '[appRequiredPerms]',
})
export class RequiredPermsDirective {
  @Input() set appRequiredPerms(permissions: PermissionEnum[]) {
    if (permissions?.length > 0) {
      this.checkPermissions(permissions);
    }
  }

  constructor(
    private readonly templateRef: TemplateRef<any>,
    private readonly viewContainer: ViewContainerRef,
    private readonly userAuthFacade: UserAuthFacade
  ) {}

  private async checkPermissions(requiredPermissions: PermissionEnum[]) {
    try {
      const myself = await firstValueFrom(this.userAuthFacade.myself$.pipe(filter(myself => !!myself?.id)));

      if (this.hasAllPermissions(myself, requiredPermissions)) {
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        this.viewContainer.clear();
      }
    } catch (error) {
      console.error('Error obtaining user data', error);
      this.viewContainer.clear();
    }
  }

  private hasAllPermissions(user: User, requiredPermissions: PermissionEnum[]) {
    return requiredPermissions.every(permission =>
      user.role.permissions.some(userPermission => userPermission.key === permission)
    );
  }
}
