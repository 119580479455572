<div class="title-container">
  <h2 mat-dialog-title>
    {{ 'PARTNER_ACCOUNT.PAYOUT_POINTS' | translate: { points: data.userPoints } }}
  </h2>
  <button mat-button (click)="closeDialog()" class="close-button-wrapper">
    <mat-icon class="close-button">close</mat-icon>
  </button>
</div>
<mat-dialog-content>
  <div *ngIf="blockingLoading; else NormalContent" class="loading-view">
    <mat-spinner [diameter]="75" [strokeWidth]="5"></mat-spinner>
  </div>

  <ng-template #NormalContent>
    <ng-container [ngSwitch]="currentView">
      <div class="view-wrapper" *ngSwitchCase="'QUESTION'">
        <div class="question-pre" [innerHTML]="'PARTNER_ACCOUNT.ANSWER_TO_QUESTION_REQUIRED' | translate"></div>
        <div class="question" [innerHTML]="'PARTNER_ACCOUNT.QUESTION' | translate"></div>
        <div class="answer-buttons">
          <button mat-raised-button (click)="questionAnswered(true)" class="positive-answer">
            {{ 'COMMON.YES' | translate }}
          </button>
          <button mat-raised-button (click)="questionAnswered(false)" class="negative-answer">
            {{ 'COMMON.NO' | translate }}
          </button>
        </div>
      </div>
      <div class="view-wrapper" *ngSwitchCase="'WRONG_ANSWER'">
        <div class="wrong-answer" [innerHTML]="'PARTNER_ACCOUNT.WRONG_ANSWER_TRY_AGAIN' | translate"></div>
        <button
          mat-raised-button
          (click)="tryAgain()"
          class="try-again-button"
          [innerHTML]="'PARTNER_ACCOUNT.TRY_AGAIN' | translate"></button>
      </div>
      <div class="view-wrapper" *ngSwitchCase="'CONFIRM_REQUEST'">
        <div
          class="confirm-request"
          [innerHTML]="'PARTNER_ACCOUNT.CONFRIM_PAYOUT_REQUEST_BY_USING_BUTTON' | translate"></div>

        <form class="transfer-data-form" nz-form [formGroup]="transferDataForm">
          <div class="form-row">
            <div class="form-column">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="name">
                  {{ 'PARTNER_ACCOUNT.TRANSFER_DATA.NAME' | translate }}
                </nz-form-label>
                <nz-form-control nzErrorTip="{{ 'PARTNER_ACCOUNT.TRANSFER_DATA.NAME_INVALID' | translate }}">
                  <input nz-input [nzSize]="'large'" formControlName="transferDataName" id="name" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div class="form-column">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="surname">
                  {{ 'PARTNER_ACCOUNT.TRANSFER_DATA.SURNAME' | translate }}
                </nz-form-label>
                <nz-form-control nzErrorTip="{{ 'PARTNER_ACCOUNT.TRANSFER_DATA.SURNAME_INVALID' | translate }}">
                  <input nz-input [nzSize]="'large'" formControlName="transferDataSurname" id="surname" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
          <div class="form-row">
            <div class="form-column">
              <nz-form-item>
                <nz-form-label [nzSm]="24" [nzXs]="24" nzRequired nzFor="bankAccountNr">
                  {{ 'PARTNER_ACCOUNT.TRANSFER_DATA.BANK_ACCOUNT_NR' | translate }}
                </nz-form-label>
                <nz-form-control
                  [nzSm]="24"
                  [nzXs]="24"
                  nzErrorTip="{{ 'PARTNER_ACCOUNT.TRANSFER_DATA.BANK_ACCOUNT_NR_INVALID' | translate }}">
                  <input nz-input [nzSize]="'large'" formControlName="transferDataBankAccountNr" id="bankAccountNr" />
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </form>

        <!-- FORM -->

        <div class="pay-out-button-wrapper">
          <button
            (click)="requestPayout()"
            [disabled]="transferDataForm.pristine || transferDataForm.invalid"
            nz-button
            nzType="primary"
            [nzSize]="'large'"
            nzShape="round">
            {{ 'PARTNER_ACCOUNT.PAY_OUT_BONUS' | translate }}
          </button>
        </div>
      </div>

      <div class="view-wrapper" *ngSwitchCase="'SUCCESS'">
        <div class="confirm-request" [innerHTML]="'PARTNER_ACCOUNT.PAY_OUT_REQUEST_SUCESS' | translate"></div>
        <button
          mat-raised-button
          (click)="closeDialog()"
          class="try-again-button"
          [innerHTML]="'PARTNER_ACCOUNT.CLOSE_DIALOG' | translate"></button>
      </div>
      <div class="view-wrapper" *ngSwitchCase="'ERROR'">
        <div class="confirm-request" [innerHTML]="'PARTNER_ACCOUNT.PAY_OUT_REQUEST_ERROR' | translate"></div>
        <button
          mat-raised-button
          (click)="closeDialog()"
          class="try-again-button"
          [innerHTML]="'PARTNER_ACCOUNT.CLOSE_DIALOG' | translate"></button>
      </div>
    </ng-container>
  </ng-template>
</mat-dialog-content>
