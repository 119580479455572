<ng-container *ngFor="let menuItem of sideMenuItems">
  <div
    *ngIf="deviceInfo.deviceTypeDetected === 'DESKTOP' || menuItem.showOnMobile"
    [routerLink]="menuItem.isDisabled(myProcess?.status) ? null : menuItem.getRouterLink(myProcess?.id)"
    routerLinkActive="active"
    [class.item-disabled]="menuItem.isDisabled(myProcess?.status)"
    class="menu-item {{ menuItem.class }}"
    nz-tooltip
    [nzTooltipTrigger]="
      isForeigner && menuItem.disabledItemTooltip && (!myProcess?.id || myProcess?.id === 'purchase') ? 'click' : null
    "
    nzTooltipTitle="{{ menuItem.disabledItemTooltip ? (menuItem.disabledItemTooltip | translate) : '' }}"
    [nzTooltipPlacement]="'right'"
    nzTooltipOverlayClassName="disabled-menu-item-tooltip"
    [nzTooltipArrowPointAtCenter]="true">
    <mat-icon *ngIf="menuItem.svgIcon" class="menu-item-icon" [svgIcon]="menuItem.svgIcon"></mat-icon>
    <span>{{ menuItem.mainLabelKey | translate }}</span>
  </div>
</ng-container>
