<div class="general-info-view">
  <div class="code-header" *ngIf="partnerCode$ | async as partnerCode">
    <h1 class="code">{{ partnerCode.code }}</h1>
    <button
      [disabled]="loading$ | async"
      (click)="copyToClipboard(partnerCode.code)"
      nz-button
      nzType="default"
      nzSize="large"
      nzShape="circle">
      <span nz-icon nzType="copy" nzTheme="outline"></span>
    </button>
  </div>

  <div class="points-list" *ngIf="partnerPointsSum$ | async as partnerPointsSum">
    <h1 [translate]="'MANAGEMENT.PARTNER.POINTS_LIST' | translate"></h1>
    <div class="points-in-status-row status-{{ pointsStatus }}" *ngFor="let pointsStatus of allStatusesOfPartnerPoints">
      <div class="points-status-label">
        {{ 'MANAGEMENT.PARTNER.POINTS_STATUS.' + pointsStatus | translate }}
      </div>

      <div class="points-quantity">
        {{ partnerPointsSum[pointsStatus] }}
      </div>
    </div>
  </div>
</div>
