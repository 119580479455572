export interface SentEmailResult {
  status: 'failed' | 'success';
  result?: any;
  error?: any;
}

export enum FollowUpEmailMode {
  'SUPPORT_CHAT' = 'SUPPORT_CHAT',
  'BACK_OFFICE' = 'BACK_OFFICE',
}
