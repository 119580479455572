import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { SUPPORTED_LANGS } from '@constants';
import { DISCOUNT_CODE_TYPE, DiscountCode, PARTNER_TERMS_CONFIG, PermissionEnum, User } from '@interfaces';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { PartnerCodesFacade } from '@state/partner-codes';
import { UserAuthFacade } from '@state/user-auth';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { combineLatest, lastValueFrom, map, ReplaySubject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-partner-account-drawer',
  templateUrl: './partner-account-drawer.component.html',
  styleUrls: ['./partner-account-drawer.component.scss'],
})
export class PartnerAccountDrawerComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  @Input() userId: string;
  @Input() userName: string;

  public myself: User;
  public partnerCode: DiscountCode;

  public loadingPartnerCode$ = this.partnerCodesFacade.loading$;
  public generationInProgress$ = this.partnerCodesFacade.codeGenerationInProgress$;

  public disablingLoading$ = combineLatest([this.loadingPartnerCode$, this.generationInProgress$]).pipe(
    map(([loadingPartnerCode, generationInProgress]) => loadingPartnerCode || generationInProgress),
    takeUntil(this.destroy$)
  );

  public loadingView: boolean;

  public DISCOUNT_CODE_TYPE = DISCOUNT_CODE_TYPE;
  public PARTNER_TERMS_CONFIG = PARTNER_TERMS_CONFIG;
  public SUPPORTED_LANGS = SUPPORTED_LANGS;
  public usingCustomTermsConfig: boolean;

  constructor(
    private readonly drawerRef: NzDrawerRef<any>,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly partnerCodesFacade: PartnerCodesFacade,
    private readonly confirmation: ConfirmationModalService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.loadingView = true;
    if (!this.userId?.length) {
      console.error('INVALID USER ID PROVIDED FOR DRAWER COMPONENT');
      this.drawerRef.close();
      return;
    }

    const myself = await lastValueFrom(this.userAuthFacade.myself$.pipe(take(1), takeUntil(this.destroy$)));

    // double check permissions
    if (!myself.role.permissions.find(({ key }) => key === PermissionEnum.CAN_MANAGE_USERS_PARTNER_ACCOUNT)) {
      console.error('YOU ARE NOT PERMITTED TO SEE PARTNER CODE DRAWER');
      this.drawerRef.close();
      return;
    }

    this.myself = myself;
    this.partnerCode = await this.partnerCodesFacade.getPartnerCodeDetails$(this.userId);

    setTimeout(() => {
      this.loadingView = false;
    }, 650);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public generatePartnerCode(): void {
    this.confirmation
      .open({
        confirmationToTranslate: 'MANAGEMENT.PARTNER.ARE_YOU_SURE_GENERATE_CODE_FOR',
        translateParams: { userName: this.userName },
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.loadingView = true;
        this.partnerCodesFacade.generatePartnerCodeForUserSuccess$
          .pipe(take(1), takeUntil(this.partnerCodesFacade.generatePartnerCodeForUserError$), takeUntil(this.destroy$))
          .subscribe(({ discountCode }) => {
            this.partnerCode = discountCode;
            this.loadingView = false;
          });
        this.partnerCodesFacade.generatePartnerCodeForUserError$
          .pipe(take(1), takeUntil(this.partnerCodesFacade.generatePartnerCodeForUserSuccess$))
          .subscribe(() => {
            this.loadingView = false;
          });

        this.partnerCodesFacade.generatePartnerCodeForUser(this.userId);
      });
  }
}
