import { DiscountCode, PartnerPointsPayout, RequestPayoutOpts, SumOfPartnerPoints } from '@interfaces';
import { createAction, props } from '@ngrx/store';

export const getMyPartnerAccountDetails = createAction('[My Partner Account] Get My Partner Account Details');
export const getMyPartnerAccountDetailsSuccess = createAction(
  '[My Partner Account] Get My Partner Account Details Success',
  props<{ discountCode: Partial<DiscountCode> }>()
);
export const getMyPartnerAccountDetailsError = createAction(
  '[My Partner Account] Get My Partner Account Details Error'
);

export const getMyPointsSum = createAction('[My Partner Account] Get My Points Sum');
export const getMyPointsSumSuccess = createAction(
  '[My Partner Account] Get My Points Sum Success',
  props<{ sumOfPoints: SumOfPartnerPoints }>()
);
export const getMyPointsError = createAction('[My Partner Account] Get My Points Sum Error');

export const isPayoutAvailable = createAction('[My Partner Account] Is payout available');
export const isPayoutAvailableSuccess = createAction(
  '[My Partner Account] Is payout available SUCCESS',
  props<{ payoutAvailable: boolean }>()
);
export const isPayoutAvailableError = createAction('[My Partner Account] Is payout available ERROR');

export const requestPayout = createAction('[My Partner Account] Request payout', props<{ opts: RequestPayoutOpts }>());
export const requestPayoutSuccess = createAction(
  '[My Partner Account] Request payout SUCCESS',
  props<{ partnerPointsPayout: PartnerPointsPayout }>()
);
export const requestPayoutError = createAction('[My Partner Account] Request payout ERROR');

export const getMyPayoutsHistory = createAction('[My Partner Account] Get My Payouts History');
export const getMyPayoutsHistorySuccess = createAction(
  '[My Partner Account] Get My Payouts History SUCCESS',
  props<{ payoutsHistory: Partial<PartnerPointsPayout>[] }>()
);
export const getMyPayoutsHistoryError = createAction('[My Partner Account] Get My Payouts History ERROR');
