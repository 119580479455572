<div *ngIf="loading$ | async; else ViewLoaded" class="loading-view">
  <mat-spinner [diameter]="80" [strokeWidth]="5"></mat-spinner>
</div>

<ng-template #ViewLoaded>
  <div class="add-points-wrapper">
    <button
      (click)="togglePointsAdding()"
      class="show-points-adding-form-button"
      nz-button
      nzType="primary"
      nzShape="round">
      <span nz-icon nzType="plus"></span>
      {{ 'MANAGEMENT.PARTNER.ADD_POINTS_MANUALLY' | translate }}
    </button>

    <div class="add-points-form-wrapper" *ngIf="addingPointsFormVisible">
      <input nz-input [nzSize]="'large'" type="number" min="1" max="99999" step="1" [(ngModel)]="pointsToAdd" />
      <button
        (click)="addPoints()"
        [disabled]="
          (loading$ | async) || !pointsToAdd || !isSafeInteger(pointsToAdd) || pointsToAdd >= 99999 || pointsToAdd <= 0
        "
        nz-button
        nzType="primary"
        nzShape="round">
        <span nz-icon nzType="plus"></span>
      </button>
    </div>
  </div>

  <div *ngIf="!!(points$ | async)?.length === false; else AllPointsView" class="no-payouts-view">
    <h1 [translate]="'MANAGEMENT.PARTNER.NO_POINTS'"></h1>
  </div>
</ng-template>

<ng-template #AllPointsView>
  <div class="table-wrapper">
    <nz-table
      [nzBordered]="true"
      [nzData]="points$ | async"
      [nzShowPagination]="false"
      #PartnerPointsTable
      [nzFrontPagination]="false">
      <thead>
        <tr>
          <th nzWidth="6%">#</th>
          <th nzWidth="28%">
            {{ 'MANAGEMENT.PARTNER.ALL_POINTS_TABLE.SOURCE' | translate }}
          </th>
          <th nzWidth="20%">
            {{ 'MANAGEMENT.PARTNER.ALL_POINTS_TABLE.QUANTITY' | translate }}
          </th>
          <th nzWidth="26%">
            {{ 'MANAGEMENT.PARTNER.ALL_POINTS_TABLE.STATUS' | translate }}
          </th>
          <th nzWidth="20%"></th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let points of PartnerPointsTable.data; index as indexOfPoints">
          <td nzWidth="6%">{{ indexOfPoints + 1 }}</td>
          <td nzWidth="28%">
            <span
              *ngIf="points.source === PARTNER_POINTS_SOURCE.PARTNER_CODE_USED"
              [innerHTML]="
                'MANAGEMENT.PARTNER.POINTS_FROM_PURCHASE_OF'
                  | translate
                    : {
                        userName: points.fromPurchase.user.name,
                        totalAmountPaid: points.fromPurchase.totalAmountPaid / 100
                      }
              ">
            </span>
            <span
              *ngIf="points.source === PARTNER_POINTS_SOURCE.ADDED_MANUALLY"
              [innerHTML]="
                'MANAGEMENT.PARTNER.POINTS_ADDED_MANUALLY_BY' | translate: { userName: points.addedByUser.name }
              ">
            </span>
          </td>
          <td nzWidth="20%">{{ points.quantity }}</td>
          <td
            nzWidth="26%"
            [class.paid-out]="points.status === PARTNER_POINTS_STATUS.PAID_OUT"
            [class.granted]="points.status === PARTNER_POINTS_STATUS.GRANTED"
            [class.revoked]="points.status === PARTNER_POINTS_STATUS.REVOKED">
            {{ 'MANAGEMENT.PARTNER.POINTS_STATUS.' + points.status | translate }}
          </td>
          <td nzWidth="20%">
            <button
              *ngIf="points.status === PARTNER_POINTS_STATUS.PENDING_VERIFICATION"
              (click)="changePointsStatus(points, PARTNER_POINTS_STATUS.GRANTED)"
              class="approve-button"
              nz-button
              nzType="primary"
              nzShape="round">
              <span nz-icon nzType="check-circle" nzTheme="outline"></span>
            </button>
            <button
              *ngIf="
                points.status === PARTNER_POINTS_STATUS.PENDING_VERIFICATION ||
                points.status === PARTNER_POINTS_STATUS.GRANTED
              "
              (click)="changePointsStatus(points, PARTNER_POINTS_STATUS.REVOKED)"
              class="cancel-button"
              nz-button
              nzType="primary"
              nzShape="round">
              <span nz-icon nzType="close-circle" nzTheme="outline"></span>
            </button>
            <button
              *ngIf="points.status === PARTNER_POINTS_STATUS.REVOKED"
              (click)="changePointsStatus(points, PARTNER_POINTS_STATUS.GRANTED)"
              class="revert-button"
              nz-button
              nzType="primary"
              nzShape="round">
              <span nz-icon nzType="rollback" nzTheme="outline"></span>
            </button>
            <button
              *ngIf="
                points.status === PARTNER_POINTS_STATUS.GRANTED ||
                points.status === PARTNER_POINTS_STATUS.PENDING_PAYOUT
              "
              (click)="changePointsStatus(points, PARTNER_POINTS_STATUS.PAID_OUT)"
              class="payout-button"
              [disabled]="!!points.payoutId"
              nz-tooltip
              [nzTooltipMouseEnterDelay]="0.2"
              [nzTooltipTitle]="'MANAGEMENT.PARTNER.CANT_PAYOUT_POINTS_FROM_EXISTING_PAYOUT' | translate"
              [nzTooltipTrigger]="!!points.payoutId ? 'hover' : null"
              nz-button
              nzType="primary"
              nzShape="round">
              <span nz-icon nzType="dollar-circle" nzTheme="outline"></span>
            </button>
            <button
              *ngIf="
                !points.payoutId &&
                (points.status === PARTNER_POINTS_STATUS.PAID_OUT ||
                  points.status === PARTNER_POINTS_STATUS.PENDING_PAYOUT)
              "
              (click)="changePointsStatus(points, PARTNER_POINTS_STATUS.GRANTED)"
              class="revert-button"
              nz-button
              nzType="primary"
              nzShape="round">
              <span nz-icon nzType="rollback" nzTheme="outline"></span>
            </button>
            <button
              *ngIf="!points.payoutId && points.status === PARTNER_POINTS_STATUS.REVOKED"
              (click)="removePoints(points)"
              class="remove-button"
              nz-button
              nzType="primary"
              nzShape="round">
              <span nz-icon nzType="delete" nzTheme="outline"></span>
            </button>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </div>
</ng-template>
