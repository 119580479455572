<div id="follow-up-email">
  <h1 mat-dialog-title>
    <ng-container>
      {{
        data.users.length === 1
          ? ('FOLLOW_UP.FOLLOW_UP_HEADER' | translate) +
            ' - ' +
            data.users[0].name +
            ' (' +
            data.users[0].language.name +
            ') '
          : ('FOLLOW_UP.FOLLOW_UP_HEADER' | translate)
      }}
    </ng-container>
  </h1>
  <div mat-dialog-content>
    <p *ngIf="data.users.length === 1">{{ 'FOLLOW_UP.FOLLOW_UP_CHOOSE_LANGUAGE' | translate }}</p>
    <mat-form-field appearance="legacy" *ngIf="data.users.length === 1 && availableLanguages">
      <mat-select [(value)]="selectedLang" (selectionChange)="confirmChangeLanguageDialog($event.value)">
        <mat-option *ngFor="let language of availableLanguages" [value]="language.key">
          {{ 'LANGUAGE.' + language.key.toUpperCase() | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
    <mat-form-field class="dialog_follow_up_title">
      <mat-label>{{ 'FOLLOW_UP.FOLLOW_UP_TEXT_EMAIL_TITLE' | translate }}</mat-label>
      <textarea matInput [(ngModel)]="messageTranslations.TITLE_VALUE"></textarea>
    </mat-form-field>

    <mat-form-field class="dialog_follow_up_subtitle">
      <mat-label>{{ 'FOLLOW_UP.FOLLOW_UP_TEXT_EMAIL_SUBTITLE' | translate }}</mat-label>
      <textarea matInput [(ngModel)]="messageTranslations.SUBTITLE_VALUE"></textarea>
    </mat-form-field>

    <mat-form-field class="dialog_follow_up_footer">
      <mat-label>{{ 'FOLLOW_UP.FOLLOW_UP_TEXT_EMAIL_FOOTER' | translate }}</mat-label>
      <textarea matInput [(ngModel)]="messageTranslations.FOOTER_VALUE"></textarea>
    </mat-form-field>

    <div class="email-center">
      <div class="email-container">
        <div class="email-header">
          <div class="email-to">
            <span class="label">To:</span> {{ data.users.length === 1 ? data.users[0].email : '[USER EMAIL]' }}
          </div>
          <div class="email-subject"><span class="label">Subject:</span> {{ messageTranslations.TITLE_VALUE }}</div>
        </div>
        <div class="email-body">
          <span>
            <b>
              <h5>
                {{
                  data.users.length === 1
                    ? messageTranslations.WELCOME + ' ' + data.users[0].name
                    : messageTranslations.WELCOME + ' [USER NAME]'
                }}
              </h5>
            </b>
          </span>
          <span>{{ messageTranslations.SUBTITLE_VALUE }}</span>
          <div class="button-span">
            <button class="login-button" nz-button nzType="primary" nzBlock>
              {{ messageTranslations.BUTTON }}
            </button>
          </div>
          <div>
            <span class="button-not-work-span">{{ messageTranslations.BUTTON_NOT_WORK }}</span>
          </div>
          <div>
            <span class="url-span">{{ appURL }}</span>
          </div>
          <div class="footer-div">
            <span class="footer-span">{{ messageTranslations.FOOTER_VALUE }}</span>
          </div>
        </div>
        <div class="email-footer"></div>
      </div>
    </div>
  </div>
  <span class="preview-span">{{ 'FOLLOW_UP.FOLLOW_UP_TEXT_EMAIL_PREVIEW' | translate }}</span>
  <div mat-dialog-actions>
    <button mat-button class="dialog_follow_up_cancel" (click)="closeDialog()">
      {{ 'FOLLOW_UP.FOLLOW_UP_CANCEL' | translate }}
    </button>
    <button mat-button class="dialog_follow_up_send" (click)="sendMessage()">
      {{ 'FOLLOW_UP.FOLLOW_UP_SEND' | translate }}
    </button>
  </div>
</div>
