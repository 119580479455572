<div class="annex-form-wrapper">
  <div class="import-wrapper">
    <button nz-button nzType="primary" (click)="importAnnex()">
      {{ 'NT2.ANNEX_IMPORT' | translate }}
    </button>
  </div>

  <form nz-form [formGroup]="form" [nzLayout]="'vertical'">
    <nz-divider [nzText]="entityGivingJobSectionTitle" nzOrientation="center">
      <ng-template #entityGivingJobSectionTitle>
        <h2>{{ 'NT2.ANNEX_1' | translate }} - {{ 'NT2.ENTITY_GIVING_JOB' | translate }}</h2>
      </ng-template>
    </nz-divider>
    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="24" [nzSpan]="6">
        <nz-form-item>
          <nz-form-control>
            <nz-radio-group formControlName="workplace_usingPersonName">
              <label nz-radio [nzValue]="false">{{ 'NT2.COMPANY_NAME' | translate }}</label>
              <label nz-radio [nzValue]="true">{{ 'NT2.NAME_AND_SURNAME' | translate }}</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <ng-container *ngIf="form.get('workplace_usingPersonName').value === false">
      <div nz-row [nzGutter]="8">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="workplace_name">
              {{ 'NT2.WORKPLACE_NAME' | translate }}
            </nz-form-label>
            <nz-form-control [nzErrorTip]="inputStandardErrors">
              <input nz-input formControlName="workplace_name" id="workplace_name" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="form.get('workplace_usingPersonName').value === true">
      <div nz-row [nzGutter]="8">
        <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="workplace_name">
              {{ 'NT2.WORKPLACE_FIRSTNAME' | translate }}
            </nz-form-label>
            <nz-form-control [nzErrorTip]="inputStandardErrors">
              <input nz-input formControlName="workplace_name" id="workplace_name" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
          <nz-form-item>
            <nz-form-label [nzRequired]="form.get('workplace_usingPersonName')?.value" nzFor="workplace_surname">
              {{ 'NT2.WORKPLACE_SURNAME' | translate }}
            </nz-form-label>
            <nz-form-control [nzErrorTip]="inputStandardErrors">
              <input nz-input formControlName="workplace_surname" id="workplace_surname" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </ng-container>
    <nz-divider [nzText]="section1" nzOrientation="center">
      <ng-template #section1>
        <h4>
          <b>{{ 'NT2.ENTITY_ADDRESS' | translate }}</b>
        </h4>
      </ng-template>
    </nz-divider>
    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6">
        <nz-form-item>
          <nz-form-control>
            <nz-radio-group formControlName="workplace_addressOutsideCountry">
              <label nz-radio [nzValue]="false">{{ 'NT2.IN_COUNTRY_ADDRESS' | translate }}</label>
              <label nz-radio [nzValue]="true">{{ 'NT2.ADDRESS_OUTSIDE_COUNTRY' | translate }}</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <app-mos-annex-address
      [initialData]="annex"
      [form]="form"
      [fp]="'workplace_'"
      [voivodeships]="voivodeships"
      [countries]="countries">
    </app-mos-annex-address>
    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="workplace_legalBasisOfBusiness">
            {{ 'NT2.LEGAL_BASIS_OF_PERFORMING_BUSINESS' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <textarea
              formControlName="workplace_legalBasisOfBusiness"
              id="workplace_legalBasisOfBusiness"
              nz-input
              placeholder="{{ 'NT2.FILL_FIELD' | translate }}"
              [nzAutosize]="{ minRows: 2, maxRows: 6 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <nz-divider [nzText]="section2" nzOrientation="center">
      <ng-template #section2>
        <h4>
          <b>{{ 'NT2.OTHER_FORMS_OF_ENTITY_IDENTIFICATION' | translate }}</b>
        </h4>
      </ng-template>
    </nz-divider>
    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzFor="workplace_pesel">
            {{ 'NT2.PESEL' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <input nz-input formControlName="workplace_pesel" id="workplace_pesel" />
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzFor="workplace_regon">
            {{ 'NT2.REGON' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <input nz-input formControlName="workplace_regon" id="workplace_regon" />
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <!-- ========================================= ERRORS FOR INPUTS TEMPLATE ================================================================== -->
    <ng-template #inputStandardErrors let-control>
      <ng-container *ngIf="control.hasError('maxlength')">{{ 'NT2.MAX_LENGTH_EXCEEDED' | translate }}</ng-container>
      <!-- <ng-container *ngIf="control.hasError('minlength')">MinLength is 6</ng-container> -->
      <ng-container *ngIf="control.hasError('required')"> {{ 'NT2.FIELD_REQUIRED' | translate }}</ng-container>
    </ng-template>
    <!-- ========================================= ERRORS FOR INPUTS TEMPLATE END ================================================================== -->

    <!-- ============================================================= PROXY PART ================================================================== -->
    <nz-divider [nzText]="entityProxyingJobSectionTitle" nzOrientation="center">
      <ng-template #entityProxyingJobSectionTitle>
        <h2>{{ 'NT2.ANNEX_1' | translate }} - {{ 'NT2.ENTITY_PROXYING_JOB' | translate }}</h2>
      </ng-template>
    </nz-divider>

    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-control>
            <label nz-checkbox formControlName="hasSeparateEmployer">{{
              'NT2.HAS_SEPARATE_EMPLOYER' | translate
            }}</label>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <ng-container *ngIf="form.get('hasSeparateEmployer')?.value === true">
      <div nz-row [nzGutter]="8">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
          <nz-form-item>
            <nz-form-control>
              <nz-radio-group formControlName="employer_usingPersonName">
                <label nz-radio [nzValue]="false">{{ 'NT2.COMPANY_NAME' | translate }}</label>
                <label nz-radio [nzValue]="true">{{ 'NT2.NAME_AND_SURNAME' | translate }}</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>

      <ng-container *ngIf="form.get('employer_usingPersonName').value === false">
        <div nz-row [nzGutter]="8">
          <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6">
            <nz-form-item>
              <nz-form-label nzRequired nzFor="employer_name">
                {{ 'NT2.WORKPLACE_NAME' | translate }}
              </nz-form-label>
              <nz-form-control [nzErrorTip]="inputStandardErrors">
                <input nz-input formControlName="employer_name" id="employer_name" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="form.get('employer_usingPersonName').value === true">
        <div nz-row [nzGutter]="8">
          <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
            <nz-form-item>
              <nz-form-label nzRequired nzFor="employer_firstname">
                {{ 'NT2.WORKPLACE_FIRSTNAME' | translate }}
              </nz-form-label>
              <nz-form-control [nzErrorTip]="inputStandardErrors">
                <input nz-input formControlName="employer_name" id="employer_firstname" />
              </nz-form-control>
            </nz-form-item>
          </div>
          <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
            <nz-form-item>
              <nz-form-label [nzRequired]="form.get('employer_usingPersonName')?.value" nzFor="employer_surname">
                {{ 'NT2.WORKPLACE_SURNAME' | translate }}
              </nz-form-label>
              <nz-form-control [nzErrorTip]="inputStandardErrors">
                <input nz-input formControlName="employer_surname" id="employer_surname" />
              </nz-form-control>
            </nz-form-item>
          </div>
        </div>
      </ng-container>
      <nz-divider [nzText]="section1" nzOrientation="center">
        <ng-template #section1>
          <h4>
            <b>{{ 'NT2.ENTITY_ADDRESS' | translate }}</b>
          </h4>
        </ng-template>
      </nz-divider>
      <div nz-row [nzGutter]="8">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
          <nz-form-item>
            <nz-form-control>
              <nz-radio-group formControlName="employer_addressOutsideCountry">
                <label nz-radio [nzValue]="false">{{ 'NT2.IN_COUNTRY_ADDRESS' | translate }}</label>
                <label nz-radio [nzValue]="true">{{ 'NT2.ADDRESS_OUTSIDE_COUNTRY' | translate }}</label>
              </nz-radio-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <app-mos-annex-address
        [initialData]="annex"
        [form]="form"
        [fp]="'employer_'"
        [voivodeships]="voivodeships"
        [countries]="countries">
      </app-mos-annex-address>
      <div nz-row [nzGutter]="8">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="employer_legalBasisOfBusiness">
              {{ 'NT2.LEGAL_BASIS_OF_PERFORMING_BUSINESS' | translate }}
            </nz-form-label>
            <nz-form-control [nzErrorTip]="inputStandardErrors">
              <textarea
                formControlName="employer_legalBasisOfBusiness"
                id="employer_legalBasisOfBusiness"
                nz-input
                placeholder="{{ 'NT2.FILL_FIELD' | translate }}"
                [nzAutosize]="{ minRows: 2, maxRows: 6 }"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <nz-divider [nzText]="section2" nzOrientation="center">
        <ng-template #section2>
          <h4>
            <b>{{ 'NT2.OTHER_FORMS_OF_ENTITY_IDENTIFICATION' | translate }}</b>
          </h4>
        </ng-template>
      </nz-divider>
      <div nz-row [nzGutter]="8">
        <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
          <nz-form-item>
            <nz-form-label nzFor="employer_pesel">
              {{ 'NT2.PESEL' | translate }}
            </nz-form-label>
            <nz-form-control [nzErrorTip]="inputStandardErrors">
              <input nz-input formControlName="employer_pesel" id="employer_pesel" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
          <nz-form-item>
            <nz-form-label nzFor="employer_regon">
              {{ 'NT2.REGON' | translate }}
            </nz-form-label>
            <nz-form-control [nzErrorTip]="inputStandardErrors">
              <input nz-input formControlName="employer_regon" id="employer_regon" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </ng-container>

    <nz-divider [nzText]="jobInfoSection" nzOrientation="center">
      <ng-template #jobInfoSection>
        <h2>{{ 'NT2.ANNEX_1' | translate }} - {{ 'NT2.INFORMATION_ABOUT_PAID_ACITIVITY' | translate }}</h2>
      </ng-template>
    </nz-divider>

    <!-- <div nz-row [nzGutter]="8">
      <div nz-col [nzSpan]="24">
        <nz-form-item>
          <nz-form-label nzFor="position">
            {{ 'NT2.POSITION' | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-cascader [nzOptions]="specialities"
                         nzNotFoundContent="{{ 'NT2.SPECIALITIES_DATA_NOT_LOADED' | translate }}"
                         nzPlaceHolder="{{ 'NT2.SELECT_SPECIALITY' | translate }}"
                         [nzMenuClassName]="'position-cascader'"
                         [nzColumnClassName]="'position-cascader-column'"
                         formControlName="position">
            </nz-cascader>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div> -->

    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="positionName">
            {{ 'NT2.POSITION' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <textarea
              formControlName="positionName"
              id="positionName"
              nz-input
              placeholder="{{ 'NT2.FILL_FIELD' | translate }}"
              [nzAutosize]="{ minRows: 2, maxRows: 6 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <nz-divider [nzText]="placeOfJobSection" nzOrientation="center">
      <ng-template #placeOfJobSection>
        <h4>
          <b>{{ 'NT2.PLACE_OF_JOB' | translate }}</b>
        </h4>
      </ng-template>
    </nz-divider>

    <app-mos-annex-address
      [initialData]="annex"
      [form]="form"
      [fp]="'place_'"
      [voivodeships]="voivodeships"
      [countries]="countries">
    </app-mos-annex-address>

    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="legalBasisOfPaidActivity">
            {{ 'NT2.LEGAL_BASIS_OF_PAID_ACTIVITY' | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-select formControlName="legalBasisOfPaidActivity" nzPlaceHolder="{{ 'NT2.SELECT_BASIS' | translate }}">
              <nz-option *ngFor="let basis of legalBasisOfWork" [nzValue]="basis" [nzLabel]="basis"> </nz-option>
            </nz-select>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label nzRequired>
            {{ 'NT2.CONTRACT_TYPE' | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-radio-group formControlName="contractType">
              <label nz-radio [nzValue]="'EMPLOYMENT'">{{ 'NT2.CONTRACT_TYPE_EMPLOYMENT' | translate }}</label>
              <label nz-radio [nzValue]="'CIVIL_LAW'">{{ 'NT2.CONTRACT_TYPE_CIVIL_LAW' | translate }}</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6">
        <nz-form-item *ngIf="form.get('contractType')?.value !== 'CIVIL_LAW'">
          <nz-form-label nzRequired>
            {{ 'NT2.WORKING_TIME_AT_FULLTIME' | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-input-number
              formControlName="workingTime"
              [nzPrecision]="3"
              nzPrecisionMode="cut"
              nzPlaceHolder="0.001"
              style="width: 100%"></nz-input-number>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item *ngIf="form.get('contractType')?.value === 'CIVIL_LAW'">
          <nz-form-label nzRequired>
            {{ 'NT2.NUMBER_OF_WORKING_HOURS' | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-input-number
              formControlName="workHoursPerWeek"
              [nzPrecision]="2"
              nzPrecisionMode="cut"
              nzPlaceHolder="0.01"
              style="width: 100%">
            </nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <nz-divider [nzText]="renumerationSection" nzOrientation="center">
      <ng-template #renumerationSection>
        <h4>
          <b>{{ 'NT2.PROPOSED_RENUMERATION' | translate }}</b>
        </h4>
      </ng-template>
    </nz-divider>

    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzRequired>
            {{ 'NT2.RENUMERATION_TYPE' | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-radio-group formControlName="typeOfPayGross">
              <label nz-radio [nzValue]="'MONTHLY'">{{ 'NT2.RENUMERATION_MONTHLY' | translate }}</label>
              <label nz-radio [nzValue]="'YEARLY'">{{ 'NT2.RENUMERATION_YEARLY' | translate }}</label>
              <label nz-radio [nzValue]="'HOURLY'">{{ 'NT2.RENUMERATION_HOURLY' | translate }}</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>
    <div nz-row nzGutter="12">
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzRequired>
            {{ 'NT2.GROSS_AMOUNT' | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-input-number
              formControlName="grossAmount"
              [nzPrecision]="2"
              nzPrecisionMode="cut"
              nzPlaceHolder="1.00"
              style="width: 100%">
            </nz-input-number>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="mainDuties">
            {{ 'NT2.MAIN_DUTIES' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <textarea
              formControlName="mainDuties"
              id="mainDuties"
              [maxlength]="490"
              nz-input
              placeholder="{{ 'NT2.FILL_FIELD' | translate }}"
              [nzAutosize]="{ minRows: 2, maxRows: 6 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <nz-divider [nzText]="blueCardSection" nzOrientation="center">
      <ng-template #blueCardSection>
        <h2>
          <b>{{ 'NT2.ANNEX_2' | translate }} - {{ 'NT2.BLUE_CARD' | translate }}</b>
        </h2>
      </ng-template>
    </nz-divider>

    <nz-divider [nzText]="periodSection" nzOrientation="center">
      <ng-template #periodSection>
        <h4>
          <b>{{ 'NT2.PAID_ACTIVITY_PERIOD' | translate }}</b>
        </h4>
      </ng-template>
    </nz-divider>

    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzRequired>
            {{ 'NT2.PERIOD_OF_WORK_FROM' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <nz-date-picker
              formControlName="periodOfWorkFrom"
              [nzFormat]="'dd.MM.yyyy'"
              nzPlaceHolder="DD.MM.YYYY"
              style="width: 100%">
            </nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
      <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
        <nz-form-item>
          <nz-form-label nzRequired>
            {{ 'NT2.PERIOD_OF_WORK_TO' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <nz-date-picker
              formControlName="periodOfWorkTo"
              [nzFormat]="'dd.MM.yyyy'"
              nzPlaceHolder="DD.MM.YYYY"
              style="width: 100%">
            </nz-date-picker>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="qualificationsType">
            {{ 'NT2.QUALIFICATIONS_TYPE' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <textarea
              formControlName="qualificationsType"
              id="qualificationsType"
              nz-input
              placeholder="{{ 'NT2.FILL_FIELD' | translate }}"
              [nzAutosize]="{ minRows: 3, maxRows: 6 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label nzRequired nzFor="qualificationsDocument">
            {{ 'NT2.QUALIFICATIONS_DOCUMENT' | translate }}
          </nz-form-label>
          <nz-form-control [nzErrorTip]="inputStandardErrors">
            <textarea
              formControlName="qualificationsDocument"
              id="qualificationsDocument"
              nz-input
              placeholder="{{ 'NT2.FILL_FIELD' | translate }}"
              [nzAutosize]="{ minRows: 3, maxRows: 6 }"></textarea>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row [nzGutter]="8">
      <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
        <nz-form-item>
          <nz-form-label nzRequired>
            {{ 'NT2.HAS_BLUE_CARD' | translate }}
          </nz-form-label>
          <nz-form-control>
            <nz-radio-group formControlName="hasBlueCard">
              <label nz-radio [nzValue]="true">{{ 'COMMON.YES' | translate }}</label>
              <label nz-radio [nzValue]="false">{{ 'COMMON.NO' | translate }}</label>
            </nz-radio-group>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <ng-container *ngIf="form.get('hasBlueCard')?.value === true">
      <div nz-row [nzGutter]="8">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
          <nz-form-item>
            <nz-form-label nzRequired>
              {{ 'NT2.COUNTRY' | translate }}
            </nz-form-label>
            <nz-form-control>
              <nz-select
                formControlName="blueCardCountry"
                nzPlaceHolder="{{ 'NT2.SELECT_COUNTRY' | translate }}"
                [nzShowSearch]="true">
                <nz-option *ngFor="let country of blueCardCountries" [nzValue]="country" [nzLabel]="country">
                </nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row [nzGutter]="8">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
          <nz-form-item>
            <nz-form-label nzRequired>
              {{ 'NT2.PERIOD_OF_STAY' | translate }}
            </nz-form-label>
            <nz-form-control>
              <textarea
                formControlName="blueCardCountryPeriodOfStay"
                id="blueCardCountryPeriodOfStay"
                nz-input
                placeholder="{{ 'NT2.FILL_FIELD' | translate }}"
                [nzAutosize]="{ minRows: 1, maxRows: 3 }"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row [nzGutter]="8">
        <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
          <nz-form-item>
            <nz-form-label nzRequired>
              {{ 'NT2.SERIE' | translate }}
            </nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="blueCardSerial" id="blueCardSerial" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
          <nz-form-item>
            <nz-form-label nzRequired>
              {{ 'NT2.NUMBER' | translate }}
            </nz-form-label>
            <nz-form-control>
              <input nz-input formControlName="blueCardNumber" id="blueCardNumber" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row [nzGutter]="8">
        <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
          <nz-form-item>
            <nz-form-label nzRequired>
              {{ 'NT2.DATE_OF_ISSUE' | translate }}
            </nz-form-label>
            <nz-form-control [nzErrorTip]="inputStandardErrors">
              <nz-date-picker
                formControlName="blueCardDateOfIssue"
                [nzFormat]="'dd.MM.yyyy'"
                nzPlaceHolder="DD.MM.YYYY"
                style="width: 100%">
              </nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzXs]="12" [nzSm]="12" [nzMd]="6">
          <nz-form-item>
            <nz-form-label nzRequired>
              {{ 'NT2.DATE_OF_EXPIRY' | translate }}
            </nz-form-label>
            <nz-form-control [nzErrorTip]="inputStandardErrors">
              <nz-date-picker
                formControlName="blueCardExpiryDate"
                [nzFormat]="'dd.MM.yyyy'"
                nzPlaceHolder="DD.MM.YYYY"
                style="width: 100%">
              </nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
      <div nz-row [nzGutter]="8">
        <div nz-col [nzXs]="24" [nzSm]="24" [nzMd]="12">
          <nz-form-item>
            <nz-form-label nzRequired>
              {{ 'NT2.BLUE_CARD_ISSUER' | translate }}
            </nz-form-label>
            <nz-form-control>
              <textarea
                formControlName="blueCardIssuer"
                id="blueCardIssuer"
                nz-input
                placeholder="{{ 'NT2.FILL_FIELD' | translate }}"
                [nzAutosize]="{ minRows: 1, maxRows: 3 }">
              </textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </ng-container>
  </form>
</div>
