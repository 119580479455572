import { DiscountCode, PartnerPointsPayout, SumOfPartnerPoints } from '@interfaces';

export type MyPartnerAccountState = Readonly<{
  loading: boolean;
  partnerCodeDetails: Partial<DiscountCode>;
  sumOfPartnerPoints: SumOfPartnerPoints;

  payoutAvailable: boolean;
  payoutsHistory: Partial<PartnerPointsPayout>[];
}>;

export const initialState: MyPartnerAccountState = {
  loading: false,
  partnerCodeDetails: null,
  sumOfPartnerPoints: null,
  payoutAvailable: false,
  payoutsHistory: null,
};
