import { Component, OnInit, AfterViewInit, OnDestroy, ViewChild, ElementRef } from '@angular/core';
import { environment } from '@environment';
import {
  FindSupportChatsOpts,
  FollowUpEmailMode,
  SupportChatInList,
  SupportChatMarker,
  SupportChatsOrder,
} from '@interfaces';
import { SupportChatFacade } from '@state/support-chat';
import { debounce } from 'lodash-es';
import { ReplaySubject, map, take, takeUntil } from 'rxjs';
import { MatDialog } from '@angular/material/dialog';
import { FollowUpEmailModalComponent } from '@shared/support-chat/follow-up-email-modal/follow-up-email-modal.component';
import { FollowUpChatModalComponent } from '@shared/support-chat/dialog-follow-up-chat/follow-up-chat-modal.component';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';

@Component({
  selector: 'app-support-chat-desktop',
  templateUrl: './support-chat-desktop.component.html',
  styleUrls: ['./support-chat-desktop.component.scss'],
})
export class SupportChatDesktopComponent implements OnInit, OnDestroy, AfterViewInit {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @ViewChild('chatsList') chatsList: ElementRef<HTMLElement>;

  public supportChatsList$ = this.supportChatFacade.supportChatsList$.pipe(
    map(hugeObject => {
      return Object.values(hugeObject);
    })
  );

  public openSupportChat$ = this.supportChatFacade.openSupportChat$;

  public loadingChatsList$ = this.supportChatFacade.loadingChatsList$;
  public loadingMoreChats$ = this.supportChatFacade.loadingMoreChats$;

  public loadingSupportChatUpdate$ = this.supportChatFacade.loadingOpenSupportChatUpdate$;
  public pagination$ = this.supportChatFacade.pagination$;

  public debouncedSearch = debounce(opts => this.searchSupportChats(opts), 400);

  public SupportChatsOrder = SupportChatsOrder;
  public SupportChatMarker = SupportChatMarker;
  public showMarkerButtonFor: string = null;
  public API_URL = environment.API_URL;

  public allChatsLoaded = false;

  private debouncedOnscroll = debounce(({ target, srcElement }) => {
    const { clientHeight } = srcElement as HTMLElement;
    const { scrollTop, scrollHeight } = target as HTMLElement;
    const shouldLoadMore = scrollHeight - scrollTop < clientHeight + 100;

    if (shouldLoadMore) {
      this.loadMoreSupportChats();
    }
  }, 150);

  constructor(
    private readonly supportChatFacade: SupportChatFacade,
    private readonly snackbarService: SnackbarService,
    private readonly confirmationModal: ConfirmationModalService,
    public dialog: MatDialog
  ) {}

  ngOnInit(): void {
    this.pagination$.pipe(takeUntil(this.destroy$)).subscribe(pagi => {
      this.allChatsLoaded = pagi.count === 0 || pagi.count >= pagi.all;
    });

    this.pagination$.pipe(take(1), takeUntil(this.destroy$)).subscribe(pagination => {
      this.supportChatFacade.searchSupportChats({
        ...pagination,
        onlyAssignedToMe: true,
        orderBy: SupportChatsOrder.LAST_MESSAGE_DATE,
      });
    });
  }

  ngAfterViewInit(): void {
    this.chatsList?.nativeElement.addEventListener('scroll', this.debouncedOnscroll);
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
    this.chatsList?.nativeElement.removeAllListeners('scroll');
  }

  public searchSupportChats(providedOpts: Partial<FindSupportChatsOpts>): void {
    this.pagination$.pipe(take(1), takeUntil(this.destroy$)).subscribe(pagination => {
      const opts: FindSupportChatsOpts = {
        ...pagination,
        ...providedOpts,
        offset: 0,
        limit: 25,
      };
      this.supportChatFacade.searchSupportChats(opts);
    });
  }

  public loadMoreSupportChats(): void {
    this.pagination$.pipe(take(1), takeUntil(this.destroy$)).subscribe(pagination => {
      // we've loaded all of the notifications
      if (pagination.count >= pagination.all) {
        this.allChatsLoaded = true;
        return;
      }

      const opts: FindSupportChatsOpts = {
        ...pagination,
        offset: pagination.offset + 25,
        limit: 25,
      };
      this.supportChatFacade.loadMoreSupportChats(opts);
    });
  }

  public addSupportChatMarker(marker: SupportChatMarker, supportChatId: string): void {
    this.supportChatFacade.addSupportChatMarker({ marker, supportChatId });
  }

  public markAsUnread(supportChatId: string): void {
    this.supportChatFacade.markSupportChatAsUnread(supportChatId);
  }

  public openFollowupDialogChat(supportChat: SupportChatInList): void {
    this.dialog.open(FollowUpChatModalComponent, {
      height: '50vh',
      width: '50vw',
      data: { supportChat },
    });
  }

  public openFollowupDialogEmail(supportChat: SupportChatInList): void {
    this.dialog.open(FollowUpEmailModalComponent, {
      height: '80vh',
      width: '50vw',
      data: { users: [supportChat.supportedUser], mode: FollowUpEmailMode.SUPPORT_CHAT },
    });
  }

  public getTranscription(supportChat: SupportChatInList): void {
    this.confirmationModal
      .open({
        confirmationToTranslate: 'MANAGEMENT.CHAT.GET_TRANSCRIPTION_MODAL',
        translateParams: { userName: supportChat.supportedUser.name },
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.supportChatFacade.getTranscriptionSuccess$
          .pipe(take(1), takeUntil(this.destroy$), takeUntil(this.supportChatFacade.getTranscriptionError$))
          .subscribe(() => {
            this.snackbarService.showInfo('MANAGEMENT.CHAT.GET_TRANSCRIPTION_SUCCESS');
          });

        this.supportChatFacade.getTranscription(supportChat.id);
      });
  }

  public openSupportChat(supportChatToOpen: SupportChatInList): void {
    this.openSupportChat$.pipe(take(1), takeUntil(this.destroy$)).subscribe(currentlyOpennedSupportChat => {
      // If no currentlu open support chat - open new and nothing else
      if (!currentlyOpennedSupportChat) {
        this.supportChatFacade.openSupportChat(supportChatToOpen.id);
        return;
      }

      // if currenlty some chat openned - close it succesfully and only then open new one
      this.supportChatFacade.closeSupportChatSuccess$.pipe(take(1), takeUntil(this.destroy$)).subscribe(() => {
        this.supportChatFacade.openSupportChat(supportChatToOpen.id);
      });

      this.supportChatFacade.closeSupportChat(currentlyOpennedSupportChat.supportChat.id);
    });
  }
}
