import { Component, ElementRef, Inject, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import * as moment from 'moment-timezone';
import { ReplaySubject } from 'rxjs';
import { filter, take, takeUntil } from 'rxjs/operators';

import { AllDeviceInfo, ROLES_KEYS, User, UserAsset, UserProcess, VoivodeshipOffice } from '@interfaces';

import { highEmployeeRoles } from '@constants';
import { VisitDateModalService } from '@shared/visit-date-modal/visit-date-modal.service';
import { ManagementFacade } from '@state/management';
import { UserAuthFacade } from '@state/user-auth';
import { UsersFacade } from '@state/users';
import { VoivodeshipsFacade } from '@state/voivodeships';
import { CloseResult, PopupOpts } from './office-visit-details-modal.service';
import { DeviceInfoService } from '@shared/device-info/device-info.service';

@Component({
  selector: 'app-office-visit-details-modal',
  templateUrl: './office-visit-details-modal.component.html',
  styleUrls: ['./office-visit-details-modal.component.scss'],
})
export class OfficeVisitDetailsModalComponent implements OnInit, OnDestroy {
  private destroy$: ReplaySubject<boolean> = new ReplaySubject(1);
  @ViewChild('dialogContent') dialogContent: ElementRef;

  public visitDate: string | null = null;
  public visitTime: string = null;
  public currentDate = moment();
  public offices: VoivodeshipOffice[] = [];

  public selectedVoivodeshipOfficeId: string;
  public visitAssistant: Partial<User> = null;
  public officeVisitReservationNumber: string = null;
  public updateStatusAndNotifyForeigner = false;

  public loadingEmployees$ = this.usersFacade.loading$;
  public loading$ = this.managementFacade.loading$;
  public employees$ = this.usersFacade.users$;
  public myself$ = this.userAuthFacade.myself$;
  public itemsSentForReview$ = this.managementFacade.itemsSentForFinalReview$;
  public loadingItemsSentForReview$ = this.managementFacade.loadingItemsSentForFinalReview$;

  public ROLES_KEYS = ROLES_KEYS;
  public isEmployee = false;

  public docsReadyToPrint = false;
  public loadingDocsButton = true;
  private visitDataChanged = false;

  public deviceInfo: AllDeviceInfo;

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: PopupOpts,
    public dialogRef: MatDialogRef<OfficeVisitDetailsModalComponent, CloseResult>,
    private readonly voivodehipsFacade: VoivodeshipsFacade,
    private readonly usersFacade: UsersFacade,
    private readonly managementFacade: ManagementFacade,
    private readonly visitModal: VisitDateModalService,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  ngOnInit(): void {
    this.deviceInfo = this.deviceInfoService.getInfo();

    this.deviceInfoService.infoEmitter.pipe(takeUntil(this.destroy$)).subscribe(info => {
      this.deviceInfo = info;
    });

    this.docsReadyToPrint = false;
    this.visitDataChanged = false;
    const {
      userProcessAsCalendarEvent: { personalDetails },
    } = this.data;

    this.managementFacade.getItemsSentForFinalReview(this.data.userProcessAsCalendarEvent.id);

    this.myself$
      .pipe(
        filter(myself => !!myself?.role?.key),
        takeUntil(this.destroy$)
      )
      .subscribe(myself => {
        this.isEmployee = highEmployeeRoles.includes(myself.role.key as ROLES_KEYS);
      });

    this.usersFacade
      .getEmployeeTypes$()
      .pipe(take(1))
      .subscribe(employeeTypes => {
        this.usersFacade.getEmployees({
          offset: 0,
          limit: 100,
          orderBy: 'user.name',
          orderDir: 'desc',
          roles: employeeTypes.map(role => role.id),
          showInactive: false,
        });
      });

    const voivoId = personalDetails?.residenceVoivodeshipDetails?.id;
    if (voivoId) {
      this.voivodehipsFacade
        .getVoivodeships$()
        .pipe(take(1), takeUntil(this.destroy$))
        .subscribe(voivos => {
          const voivo = voivos.find(({ id }) => id === voivoId);
          if (voivo) {
            this.offices = voivo.offices;
          }
        });
    } else {
      console.warn('NO_VOIVOID_CANT_FETCH_OFFICES');
    }

    this.setupVisitData(this.data.userProcessAsCalendarEvent);
    this.loadingDocsButton = true;
    this.managementFacade
      .areDocsGenerated$(this.data.user.id, this.data.userProcessAsCalendarEvent.id)
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(docsGenerated => {
        this.loadingDocsButton = false;
        this.docsReadyToPrint = docsGenerated;
      });

    this.managementFacade.printAllDocumentsSuccess$.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.loadingDocsButton = false;
    });
  }

  public setupVisitData(userProcess: UserProcess): void {
    const { visitAssistant, selectedVoivodeshipOfficeId, officeVisitReservationNumber, immigrationOfficeVisitDate } =
      userProcess;
    if (visitAssistant) {
      this.visitAssistant = visitAssistant;
    }

    if (selectedVoivodeshipOfficeId) {
      this.selectedVoivodeshipOfficeId = selectedVoivodeshipOfficeId;
    }
    if (officeVisitReservationNumber) {
      this.officeVisitReservationNumber = officeVisitReservationNumber;
    }

    if (immigrationOfficeVisitDate) {
      this.visitDate = moment(immigrationOfficeVisitDate).format('DD.MM.yyyy');
      this.visitTime = moment(immigrationOfficeVisitDate).format('HH:mm');
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public compareUsers(userA: Partial<User>, userB: Partial<User>): boolean {
    return userA.id === userB.id;
  }

  public printDocs(): void {
    this.loadingDocsButton = true;
    this.managementFacade.printAllDocumentsOfForeigner(this.data.user.id, this.data.userProcessAsCalendarEvent.id);
  }

  public close(): void {
    this.dialogRef.close({ visitDataChanged: this.visitDataChanged });
  }

  public async downloadItemSentForFinalReview(userAsset: UserAsset): Promise<void> {
    this.managementFacade.downloadItemSentForFinalReview({
      userAssetId: userAsset.id,
      userProcessId: userAsset.userProcessId,
    });
  }

  public openVisitModal(): void {
    this.visitModal
      .open({
        markNotificationOnSave: false,
        userProcess: this.data.userProcessAsCalendarEvent,
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }
        const {
          immigrationOfficeVisitDate,
          selectedVoivodeshipOfficeId,
          assistantId,
          notifyForeigner,
          officeVisitReservationNumber,
          updateStatus,
        } = res;

        if (!immigrationOfficeVisitDate || !selectedVoivodeshipOfficeId) {
          return;
        }

        this.managementFacade.setVisitDateSuccess$
          .pipe(take(1), takeUntil(this.destroy$))
          .subscribe(({ updatedUserProcess }) => {
            this.data.userProcessAsCalendarEvent = {
              // CalendarEvent  data
              ...this.data.userProcessAsCalendarEvent,
              ...updatedUserProcess,
            };
            this.setupVisitData(this.data.userProcessAsCalendarEvent);
            this.visitDataChanged = true;
          });

        this.managementFacade.setVisitDate({
          userId: this.data.user.id,
          userProcessId: this.data.userProcessAsCalendarEvent.id,
          immigrationOfficeVisitDate,
          selectedVoivodeshipOfficeId,
          assistantId,
          notifyForeigner,
          officeVisitReservationNumber,
          updateStatus,
        });
      });
  }
}
