import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { SUPPORTED_LANGS } from '@constants';
import { DISCOUNT_CODE_TYPE, DiscountCode, PARTNER_TERMS_CONFIG, PermissionEnum, User } from '@interfaces';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { PartnerCodesFacade } from '@state/partner-codes';
import { UserAuthFacade } from '@state/user-auth';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { combineLatest, lastValueFrom, map, ReplaySubject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-partner-code-form',
  templateUrl: './partner-code-form.component.html',
  styleUrls: ['./partner-code-form.component.scss'],
})
export class PartnerCodeFormComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() userId: string;
  @Input() userName: string;

  public myself: User;
  public partnerCode: DiscountCode;

  public loadingPartnerCode$ = this.partnerCodesFacade.loading$;
  public generationInProgress$ = this.partnerCodesFacade.codeGenerationInProgress$;

  public disablingLoading$ = combineLatest([this.loadingPartnerCode$, this.generationInProgress$]).pipe(
    map(([loadingPartnerCode, generationInProgress]) => loadingPartnerCode || generationInProgress),
    takeUntil(this.destroy$)
  );

  public DISCOUNT_CODE_TYPE = DISCOUNT_CODE_TYPE;
  public PARTNER_TERMS_CONFIG = PARTNER_TERMS_CONFIG;
  public SUPPORTED_LANGS = SUPPORTED_LANGS;
  public usingCustomTermsConfig: boolean;

  public partnerCodeForm: FormGroup<any> = this.fb.group({
    id: [null],
    partnerTermsConfig: [PARTNER_TERMS_CONFIG.GLOBAL, Validators.compose([Validators.required])],
    discountType: [null, Validators.compose([Validators.required])],
    discountAmount: [null, Validators.compose([Validators.min(1), Validators.max(999999)])],
    discountPercentage: [null, Validators.compose([Validators.min(1), Validators.max(99)])],
    customTerms_pointsRatio: [null],
    customTerms_slogan: this.fb.group({
      pl: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(5000)])],
      en: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(5000)])],
      ru: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(5000)])],
      uk: ['', Validators.compose([Validators.required, Validators.minLength(1), Validators.maxLength(5000)])],
    }),
  });

  constructor(
    private readonly drawerRef: NzDrawerRef<any>,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly partnerCodesFacade: PartnerCodesFacade,
    private readonly confirmation: ConfirmationModalService,
    private readonly fb: FormBuilder,
    private readonly snackService: SnackbarService
  ) {}

  public async ngOnInit(): Promise<void> {
    if (!this.userId?.length) {
      console.error('INVALID USER ID PROVIDED FOR DRAWER COMPONENT');
      this.drawerRef.close();
      return;
    }

    const myself = await lastValueFrom(this.userAuthFacade.myself$.pipe(take(1), takeUntil(this.destroy$)));

    // double check permissions
    if (!myself.role.permissions.find(({ key }) => key === PermissionEnum.CAN_MANAGE_USERS_PARTNER_ACCOUNT)) {
      console.error('YOU ARE NOT PERMITTED TO SEE PARTNER CODE DRAWER');
      this.drawerRef.close();
      return;
    }

    this.myself = myself;
    this.partnerCode = await this.partnerCodesFacade.getPartnerCodeDetails$(this.userId);

    this.setupForm();
    this.setupFormBehaviour();
  }

  ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public regenerateCode(): void {
    this.confirmation
      .open({
        confirmationToTranslate: 'MANAGEMENT.PARTNER.REGENERATION_WARNING',
        translateParams: { userName: this.userName },
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.partnerCodesFacade.regeneratePartnerCodeSuccess$
          .pipe(take(1), takeUntil(this.partnerCodesFacade.regeneratePartnerCodeError$), takeUntil(this.destroy$))
          .subscribe(({ discountCode }) => {
            this.partnerCode = discountCode;
          });
        this.partnerCodesFacade.regeneratePartnerCodeError$
          .pipe(take(1), takeUntil(this.partnerCodesFacade.regeneratePartnerCodeSuccess$))
          .subscribe(() => {});

        this.partnerCodesFacade.regeneratePartnerCodeForUser(this.userId);
      });
  }

  public togglePartnerTerms(): void {
    const usingCustom = !this.usingCustomTermsConfig;

    let confirmationToTranslate = 'MANAGEMENT.PARTNER.ARE_YOU_SURE_SET_CUSTOM';
    if (!usingCustom) {
      confirmationToTranslate = 'MANAGEMENT.PARTNER.ARE_YOU_SURE_SET_GLOBAL';
    }

    this.confirmation
      .open({ confirmationToTranslate })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          this.usingCustomTermsConfig = !usingCustom;
          return;
        }

        this.usingCustomTermsConfig = usingCustom;
        const val = usingCustom ? PARTNER_TERMS_CONFIG.CUSTOM : PARTNER_TERMS_CONFIG.GLOBAL;
        this.partnerCodeForm.controls.partnerTermsConfig.setValue(val);
        this.partnerCodeForm.markAsDirty();
        this.partnerCodeForm.markAllAsTouched();
        this.partnerCodeForm.updateValueAndValidity();
      });
  }

  public cancel(): void {
    const hasChanges = this.partnerCodeForm.dirty;

    if (!hasChanges) {
      this.drawerRef.close();
      return;
    }

    this.confirmation
      .open({
        confirmationToTranslate: 'MANAGEMENT.PARTNER.YOU_HAVE_UNSAVED_CHANGES',
        noButtonKey: 'MANAGEMENT.PARTNER.NO_DONT_CLOSE',
        yesButtonKey: 'MANAGEMENT.PARTNER.CLOSE_WITHOUT_SAVE',
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.drawerRef.close();
      });
  }

  public save(): void {
    const rawPartnerCodeValue = this.partnerCodeForm.getRawValue();
    rawPartnerCodeValue.discountAmount = rawPartnerCodeValue.discountAmount * 100;

    this.partnerCodesFacade.editPartnerCodeSuccess$
      .pipe(take(1), takeUntil(this.partnerCodesFacade.editPartnerCodeError$), takeUntil(this.destroy$))
      .subscribe(({ discountCode }) => {
        this.partnerCode = discountCode;
        this.setupForm();

        this.snackService.showInfo('MANAGEMENT.PARTNER.PARTNER_SETTINGS_SAVED_SUCCESSFULLY', {
          userName: this.userName,
        });

        this.partnerCodeForm.markAsPristine();
        this.partnerCodeForm.markAsUntouched();
        this.partnerCodeForm.updateValueAndValidity();
        // setTimeout(() => this.drawerRef.close(), 100);
      });

    this.partnerCodesFacade.editPartnerCodeError$
      .pipe(take(1), takeUntil(this.partnerCodesFacade.editPartnerCodeSuccess$), takeUntil(this.destroy$))
      .subscribe(() => {
        this.snackService.showError('MANAGEMENT.PARTNER.ERROR_SAVING_PARTNER_SETTINGS', { userName: this.userName });
      });

    const { id, ...editOpts } = rawPartnerCodeValue;
    editOpts.userId = this.userId;
    this.partnerCodesFacade.editCodeOfPartner(editOpts);
  }

  private setupForm(): void {
    const {
      id,
      discountAmount,
      discountPercentage,
      discountType,
      customTerms_pointsRatio,
      customTerms_slogan,
      partnerTermsConfig,
    } = this.partnerCode;

    this.partnerCodeForm.setValue({
      id,
      partnerTermsConfig,
      discountType,
      discountAmount: discountAmount / 100,
      discountPercentage,
      customTerms_pointsRatio,
      customTerms_slogan,
    });

    this.usingCustomTermsConfig = partnerTermsConfig === PARTNER_TERMS_CONFIG.CUSTOM;

    if (partnerTermsConfig === PARTNER_TERMS_CONFIG.GLOBAL) {
      this.partnerCodeForm.disable({ onlySelf: false, emitEvent: false });
      this.partnerCodeForm.controls.partnerTermsConfig.enable({ onlySelf: true, emitEvent: false });
      return;
    }

    if (partnerTermsConfig === PARTNER_TERMS_CONFIG.CUSTOM) {
      this.partnerCodeForm.enable({ onlySelf: false, emitEvent: false });
    }
  }

  private setupFormBehaviour(): void {
    this.partnerCodeForm.controls.partnerTermsConfig.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      this.usingCustomTermsConfig = val === PARTNER_TERMS_CONFIG.CUSTOM;

      if (val === PARTNER_TERMS_CONFIG.GLOBAL) {
        const { discountAmount, discountPercentage, discountType, pointsRatio, slogan } = this.partnerCode.globalTerms;
        this.partnerCodeForm.patchValue({
          discountType,
          discountAmount: discountAmount / 100,
          discountPercentage,
          customTerms_pointsRatio: pointsRatio,
          customTerms_slogan: { ...slogan },
        });

        this.partnerCodeForm.disable({ emitEvent: false, onlySelf: false });
        this.partnerCodeForm.controls.partnerTermsConfig.enable({ onlySelf: true, emitEvent: false });
      }

      if (val === PARTNER_TERMS_CONFIG.CUSTOM) {
        this.partnerCodeForm.enable({ emitEvent: false, onlySelf: false });
        this.partnerCodeForm.updateValueAndValidity({ onlySelf: false, emitEvent: false });
      }
    });

    this.partnerCodeForm.controls.discountType.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(val => {
      if (val === DISCOUNT_CODE_TYPE.AMOUNT) {
        this.partnerCodeForm.controls.discountAmount.setValidators(
          Validators.compose([Validators.required, Validators.min(1), Validators.max(999999)])
        );
        this.partnerCodeForm.controls.discountAmount.updateValueAndValidity();
        this.partnerCodeForm.controls.discountPercentage.clearValidators();
        this.partnerCodeForm.controls.discountPercentage.updateValueAndValidity();
      }

      if (val === DISCOUNT_CODE_TYPE.PERCENTAGE) {
        this.partnerCodeForm.controls.discountPercentage.setValidators(
          Validators.compose([Validators.required, Validators.min(1), Validators.max(99)])
        );
        this.partnerCodeForm.controls.discountPercentage.updateValueAndValidity();
        this.partnerCodeForm.controls.discountAmount.clearValidators();
        this.partnerCodeForm.controls.discountAmount.updateValueAndValidity();
      }
    });
  }
}
