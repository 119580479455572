import {
  DiscountCode,
  Pagination,
  PartnerGlobalTerms,
  PartnerPoints,
  PartnerPointsPayout,
  Purchase,
  SumOfPartnerPoints,
  User,
} from '@interfaces';

export type PartnerCodesState = Readonly<{
  loading: boolean;
  loadingList: boolean;

  globalTerms: PartnerGlobalTerms;

  partners: Partial<User>[];
  partnersPagination: Pagination;

  payouts: PartnerPointsPayout[];
  payoutsPagination: Pagination;

  partnerCodeDetails: DiscountCode;
  codeGenerationInProgress: boolean;

  sumOfPartnerPoints: SumOfPartnerPoints;
  partnerCodeUsage: Partial<Purchase[]>;
  partnerPayouts: PartnerPointsPayout[];
  allPartnerPoints: PartnerPoints[];
}>;

export const defaultPartnersPagination = {
  all: 0,
  limit: 25,
  offset: 0,
  orderBy: 'name',
  orderDir: 'desc',
};
export const defaultPayoutsPagination = {
  all: 0,
  limit: 25,
  offset: 0,
  orderBy: 'createdAt',
  orderDir: 'asc',
};

export const initialState: PartnerCodesState = {
  loading: false,
  loadingList: false,

  globalTerms: null,

  partners: null,
  partnersPagination: { ...(defaultPartnersPagination as Pagination) },
  payouts: null,
  payoutsPagination: { ...(defaultPayoutsPagination as Pagination) },

  partnerCodeDetails: null,
  codeGenerationInProgress: false,

  sumOfPartnerPoints: null,
  partnerCodeUsage: null,
  partnerPayouts: null,
  allPartnerPoints: null,
};
