import { Action, createReducer, on } from '@ngrx/store';
import * as actions from './my-partner-account.actions';
import { initialState, MyPartnerAccountState } from './my-partner-account.state';

const reducer = createReducer(
  initialState,

  on(actions.isPayoutAvailable, state => ({
    ...state,
    loading: true,
    payoutAvailable: false,
  })),
  on(actions.isPayoutAvailableSuccess, (state, { payoutAvailable }) => ({
    ...state,
    loading: false,
    payoutAvailable,
  })),
  on(actions.isPayoutAvailableError, state => ({
    ...state,
    loading: false,
    payoutAvailable: false,
  })),

  on(actions.requestPayout, state => ({
    ...state,
    loading: true,
    payoutAvailable: false,
  })),
  on(actions.requestPayoutSuccess, state => ({
    ...state,
    loading: false,
    payoutAvailable: false,
  })),
  on(actions.requestPayoutError, state => ({
    ...state,
    loading: false,
    payoutAvailable: false,
  })),
  on(actions.getMyPartnerAccountDetails, actions.getMyPointsSum, state => ({
    ...state,
    loading: true,
  })),
  on(actions.getMyPartnerAccountDetailsSuccess, (state, { discountCode }) => ({
    ...state,
    partnerCodeDetails: discountCode,
    loading: false,
  })),
  on(actions.getMyPointsSumSuccess, (state, { sumOfPoints }) => ({
    ...state,
    sumOfPartnerPoints: sumOfPoints,
    loading: false,
  })),
  on(actions.getMyPayoutsHistory, state => ({
    ...state,
    loading: true,
  })),
  on(actions.getMyPayoutsHistorySuccess, (state, { payoutsHistory }) => ({
    ...state,
    loading: false,
    payoutsHistory,
  })),
  on(actions.getMyPartnerAccountDetailsError, actions.getMyPointsError, actions.getMyPayoutsHistoryError, state => ({
    ...state,
    loading: false,
  }))
);

export function myPartnerAccountReducer(
  state: MyPartnerAccountState | undefined,
  action: Action
): MyPartnerAccountState {
  return reducer(state, action);
}
