import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PartnerPoints, Purchase } from '@interfaces';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { PartnerCodesFacade } from '@state/partner-codes';
import { UserAuthFacade } from '@state/user-auth';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { ReplaySubject } from 'rxjs';

@Component({
  selector: 'app-code-usage',
  templateUrl: './code-usage.component.html',
  styleUrls: ['./code-usage.component.scss'],
})
export class CodeUsageComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() userId: string;
  @Input() userName: string;

  public loading$ = this.partnerCodesFacade.loading$;

  public purchases: Partial<Purchase[]> = [];

  constructor(
    private readonly drawerRef: NzDrawerRef<any>,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly partnerCodesFacade: PartnerCodesFacade,
    private readonly confirmation: ConfirmationModalService
  ) {}

  public async ngOnInit(): Promise<void> {
    if (!this.userId?.length) {
      console.error('INVALID USER ID PROVIDED FOR DRAWER COMPONENT');
      this.drawerRef.close();
      return;
    }

    this.purchases = (await this.partnerCodesFacade.getPartnerCodeUsage$(this.userId)).map(purchase => {
      let summedPointsForPurchase = 0;
      if (purchase.partnerPointsAdded?.length) {
        summedPointsForPurchase = purchase.partnerPointsAdded.reduce((acc, points) => {
          acc += points.quantity;
          return acc;
        }, 0);
      }

      return {
        ...purchase,
        partnerPointsAdded: [{ quantity: summedPointsForPurchase } as PartnerPoints],
      };
    });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
