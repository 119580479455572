import { NzDrawerRef } from 'ng-zorro-antd/drawer';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PARTNER_POINTS_PAYOUT_STATUS, PayoutsInView } from '@interfaces';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { PartnerCodesFacade } from '@state/partner-codes';
import { UserAuthFacade } from '@state/user-auth';
import * as moment from 'moment-timezone';
import { map, Observable, ReplaySubject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-partner-payouts-history',
  templateUrl: './partner-payouts-history.component.html',
  styleUrls: ['./partner-payouts-history.component.scss'],
})
export class PartnerPayoutsHistoryComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() userId: string;
  @Input() userName: string;

  public loading$ = this.partnerCodesFacade.loading$;
  public payouts$: Observable<PayoutsInView[]> = this.partnerCodesFacade.partnerPayouts$.pipe(
    map(payouts => {
      if (!payouts?.length) {
        return payouts;
      }

      return payouts.map(payout => {
        let summedPointsInPayout = 0;
        if (payout?.pointsInPayout?.length) {
          summedPointsInPayout = payout.pointsInPayout.reduce((acc, pointsInPayout) => {
            acc += pointsInPayout.quantity;
            return acc;
          }, 0);
        }

        const isCancellable =
          payout.status === PARTNER_POINTS_PAYOUT_STATUS.REQUESTED &&
          moment(payout.requestedAt).isSameOrAfter(moment().subtract(15, 'days'));

        const isRevertable =
          payout.status === PARTNER_POINTS_PAYOUT_STATUS.PAID_OUT &&
          moment(payout.paidOutAt).isSameOrAfter(moment().subtract(5, 'days'));

        return {
          ...payout,
          totalPointsInPayout: summedPointsInPayout,

          isCancellable,
          isRevertable,
          isPaidOut: payout.status === PARTNER_POINTS_PAYOUT_STATUS.PAID_OUT,
          isPending: payout.status === PARTNER_POINTS_PAYOUT_STATUS.REQUESTED,
        };
      });
    })
  ) as Observable<PayoutsInView[]>;

  constructor(
    private readonly drawerRef: NzDrawerRef<any>,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly partnerCodesFacade: PartnerCodesFacade,
    private readonly confirmation: ConfirmationModalService
  ) {
    moment.tz.setDefault('Europe/Warsaw');
  }

  public async ngOnInit(): Promise<void> {
    if (!this.userId?.length) {
      console.error('INVALID USER ID PROVIDED FOR DRAWER COMPONENT');
      this.drawerRef.close();
      return;
    }

    this.initPayoutsData();
  }

  public async initPayoutsData(): Promise<void> {
    this.partnerCodesFacade.getPartnerPayoutsHistory(this.userId);
  }

  public confirmPayout(payout: PayoutsInView): void {
    this.confirmation
      .open({
        confirmationToTranslate: 'MANAGEMENT.PARTNER.CONFIRM_TRANSFERRING_REWARD_FOR_POINTS_OF_USER',
        translateParams: {
          points: payout.totalPointsInPayout,
          userName: this.userName,
          transferData: payout.transferDataAnonymised,
        },
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.partnerCodesFacade.changePayoutStatus({
          payoutId: payout.id,
          status: PARTNER_POINTS_PAYOUT_STATUS.PAID_OUT,
          userId: payout.partnerId,
        });
      });
  }

  public cancelPayout(payout: PayoutsInView): void {
    this.confirmation
      .open({
        confirmationToTranslate: 'MANAGEMENT.PARTNER.CONFIRM_CANCELLING_PAYOUT',
        translateParams: { points: payout.totalPointsInPayout, userName: this.userName },
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.partnerCodesFacade.cancelPayout({
          payoutId: payout.id,
          userId: payout.partnerId,
        });
      });
  }

  public revertPayout(payout: PayoutsInView): void {
    this.confirmation
      .open({
        confirmationToTranslate: 'MANAGEMENT.PARTNER.CONFIRM_REVERTING_PAYOUT',
        translateParams: { points: payout.totalPointsInPayout, userName: this.userName },
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.partnerCodesFacade.changePayoutStatus({
          payoutId: payout.id,
          userId: payout.partnerId,
          status: PARTNER_POINTS_PAYOUT_STATUS.REQUESTED,
        });
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
