import {
  GetEmployeesPayload,
  GetForeignersPayload,
  GetUsersPayload,
  PaginatedResponse,
  ReassignForeignersOpts,
  Role,
  SelfRegisterOpts,
  User,
} from '@interfaces';
import { createAction, props } from '@ngrx/store';

// getForeigners use 'getUsersSuccess' on success
export const getForeigners = createAction('[Users] Get Foreigners', props<{ payload: GetForeignersPayload }>());
// getEmployees use 'getUsersSuccess' on success
export const getEmployees = createAction('[Users] Get Employees', props<{ payload: GetEmployeesPayload }>());
// getManagers use 'getUsersSuccess' on success
export const getManagers = createAction('[Users] Get Managers', props<{ payload: GetUsersPayload }>());
// getCompanyEmployees use 'getUsersSuccess' on success - used by managers
// companyId in payload is ignored - backend is fetching companyId from requesting user object
export const getCompanyEmployees = createAction('[Users] Get Company Employees', props<{ payload: GetUsersPayload }>());

export const getUsers = createAction('[Users] Get Users', props<{ payload: GetUsersPayload }>());
export const getUsersSuccess = createAction('[Users] Get Users Success', props<{ users: PaginatedResponse<User> }>());
export const getUsersError = createAction('[Users] Get Users Error', props<{ errorMessage: string }>());

export const getUser = createAction('[Users] Get User', props<{ userId: string }>());
export const getUserSuccess = createAction('[Users] Get User Success', props<{ user: User }>());
export const getUserError = createAction('[Users] Get User Error', props<{ errorMessage: string }>());

// createForeigner use 'createUserSuccess' on success
// processId?: [uuid] or null
export const createForeigner = createAction('[Users] Create Foreigner', props<{ payload: Partial<User> }>());
// createEmployee use 'createUserSuccess' on success
export const createEmployee = createAction('[Users] Create Employee', props<{ payload: Partial<User> }>());
// createManager use 'createUserSuccess' on success
export const createManager = createAction('[Users] Create Manager', props<{ payload: Partial<User> }>());
export const createUserSuccess = createAction('[Users] Create User Success', props<{ createdUser: User }>());
export const createUserError = createAction('[Users] Create User Error', props<{ errorMessage: string }>());

export const resendEmployeeActivation = createAction(
  '[Users] Resend employee activation link',
  props<{ email: string; userId: string }>()
);
export const resendForeignerActivation = createAction(
  '[Users] Resend foreigner activation link',
  props<{ email: string; userId: string }>()
);
export const resendManagerActivation = createAction(
  '[Users] Resend manager activation link',
  props<{ email: string; userId: string }>()
);
export const resendActivationSuccess = createAction(
  '[Users] Resend activation link success',
  props<{ emailSent: boolean }>()
);
export const resendActivationError = createAction(
  '[Users] Resend activation link error',
  props<{ errorMessage: string }>()
);

// updateForeigner use 'updateUserSuccess' on success
export const updateForeigner = createAction(
  '[Users] Update Foreigner',
  props<{ payload: Partial<User>; userId: string }>()
);
// updateEmployee use 'updateUserSuccess' on success
export const updateEmployee = createAction(
  '[Users]  Update Employee',
  props<{ payload: Partial<User>; userId: string }>()
);
// updateEmployee use 'updateUserSuccess' on success
export const updateManager = createAction(
  '[Users]  Update Manager',
  props<{ payload: Partial<User>; userId: string }>()
);

export const updateUserSuccess = createAction('[Users] Update User Success', props<{ updatedUser: User }>());
export const updateUserError = createAction('[Users] Update User Error', props<{ errorMessage: string }>());

// removeForeigner use 'removeUserSuccess' on success
export const removeForeigner = createAction('[Users] Remove Foreigner', props<{ userId: string }>());
// removeEmployee use 'removeUserSuccess' on success
export const removeEmployee = createAction('[Users]  Remove Employee', props<{ userId: string }>());
// removeManager use 'removeUserSuccess' on success
export const removeManager = createAction('[Users]  Remove Manager', props<{ userId: string }>());
export const removeUserSuccess = createAction('[Users] Remove User Success', props<{ removedUserId: string }>());
export const removeUserError = createAction('[Users] Remove User Error', props<{ errorMessage: string }>());

export const restoreUser = createAction('[Users] Restore User', props<{ userId: string }>());

export const restoreForeigner = createAction('[Users] Restore Foreigner', props<{ foreignerId: string }>());
export const restoreUserSuccess = createAction(
  '[Users] Restore User Success',
  props<{ restoredUser: Partial<User> }>()
);

export const restoreUserError = createAction('[Users] Restore User Error', props<{ errorMessage: string }>());

export const getEmployeeTypes = createAction('[Users] Get Employee Types');
export const getEmployeeTypesSuccess = createAction(
  '[Users] Get Employee Types Success',
  props<{ employeeTypes: Role[] }>()
);
export const getEmployeeTypesError = createAction('[Users] Get Employee Types Error');

export const selfRegister = createAction('[Users] Self Register', props<{ opts: SelfRegisterOpts }>());
export const selfRegisterSuccess = createAction('[Users] Self Register Success');
export const selfRegisterError = createAction('[Users] Self Register Error', props<{ errorMessage: string }>());
export const getAllRoles = createAction('[Users] Get All Roles');
export const getAllRolesSuccess = createAction('[Users] Get All Roles Success', props<{ allRoles: Role[] }>());
export const getAllRolesError = createAction('[Users] Get All Roles Error');

export const reassignForeigners = createAction(
  '[Users] Reassign foreigners',
  props<{ opts: ReassignForeignersOpts }>()
);
export const reassignForeignersSuccess = createAction('[Users] Reassign foreigners Success');
export const reassignForeignersError = createAction('[Users] Reassign foreigners Error');
