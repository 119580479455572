import { Clipboard } from '@angular/cdk/clipboard';
import { Component, ElementRef, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { AvailableLanguages, SUPPORTED_LANGS } from '@constants';
import {
  DISCOUNT_CODE_TYPE,
  DiscountCode,
  PARTNER_POINTS_STATUS,
  PARTNER_TERMS_CONFIG,
  ROLES_KEYS,
  SumOfPartnerPoints,
  User,
} from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { SnackbarService } from '@shared/snack-bar/snack-bar.service';
import { MyPartnerAccountFacade } from '@state/my-partner-account';
import { UserAuthFacade } from '@state/user-auth';
import { NzDrawerRef } from 'ng-zorro-antd/drawer';
import { combineLatest, lastValueFrom, map, ReplaySubject, skip, take, takeUntil } from 'rxjs';
import { PayoutRequestModalService } from './payout-request-modal/payout-request-modal.service';

@Component({
  selector: 'app-my-partner-account-drawer',
  templateUrl: './my-partner-account-drawer.component.html',
  styleUrls: ['./my-partner-account-drawer.component.scss'],
})
export class MyPartnerAccountDrawerComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() userName: string;

  @ViewChild('circle1') circle1Element: ElementRef<HTMLElement>;
  @ViewChild('circle2') circle2Element: ElementRef<HTMLElement>;
  @ViewChild('circle3') circle3Element: ElementRef<HTMLElement>;

  public myself: User;

  public partnerCode: Partial<DiscountCode>;
  public sumOfPartnerPoints: SumOfPartnerPoints;

  public loadingPartnerCode$ = this.myPartnerAccountFacade.loading$;
  public payoutAvailable$ = this.myPartnerAccountFacade.payoutAvailable$;
  public payoutsHistory$ = this.myPartnerAccountFacade.payoutsHistory$;
  public payoutsHistoryLoading: boolean;

  public disablingLoading$ = combineLatest([this.loadingPartnerCode$]).pipe(
    map(([loadingPartnerCode]) => loadingPartnerCode),
    takeUntil(this.destroy$)
  );

  public loadingView: boolean;

  public DISCOUNT_CODE_TYPE = DISCOUNT_CODE_TYPE;
  public PARTNER_TERMS_CONFIG = PARTNER_TERMS_CONFIG;
  public PARTNER_POINTS_STATUS = PARTNER_POINTS_STATUS;
  public SUPPORTED_LANGS = SUPPORTED_LANGS;

  public userLang: AvailableLanguages;
  public hoveredOnSumCategory: {
    categoryName: PARTNER_POINTS_STATUS;
    pointsSum: number;
  } = null;

  public instructionsDrawerVisible = false;
  public codeUsageDrawerVisible = false;
  public payoutsHistoryDrawerVisible = false;
  public isDesktop: boolean;
  public payoutAllowed: boolean;
  public title: string;

  constructor(
    private readonly drawerRef: NzDrawerRef<any>,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly myPartnerAccountFacade: MyPartnerAccountFacade,
    private readonly confirmation: ConfirmationModalService,
    private readonly snackService: SnackbarService,
    private readonly clipboard: Clipboard,
    private readonly translateService: TranslateService,
    private readonly deviceInfo: DeviceInfoService,
    private readonly payoutRequestModal: PayoutRequestModalService
  ) {}

  public async ngOnInit(): Promise<void> {
    this.loadingView = true;
    this.userLang = this.translateService.currentLang as AvailableLanguages;
    this.isDesktop = this.deviceInfo.getDeviceType() === 'DESKTOP';

    this.myself = await lastValueFrom(this.userAuthFacade.myself$.pipe(take(1), takeUntil(this.destroy$)));

    const rolesAllowedToParticipate = [ROLES_KEYS.Foreigner];
    const userRole = this.myself.role.key as ROLES_KEYS;
    const allowedToParticipateInPartnerProgram =
      rolesAllowedToParticipate.includes(userRole) && this.myself.company?.purchaseEnabled;

    if (!allowedToParticipateInPartnerProgram) {
      this.drawerRef.close();
      console.error('NOT_ALLOWED_TO_PARTICIPATE');
      return;
    }
    await this.initData();
    this.title = this.translateService.instant('PARTNER_ACCOUNT.YOUR_PARTNER_ACCOUNT');

    setTimeout(() => {
      this.loadingView = false;
      if (this.partnerCode?.code) {
        setTimeout(() => this.animateCircles(), 100);
      }
    }, 650);
  }

  private async initData(): Promise<void> {
    const [partnerCode, sumOfPartnerPoints] = await Promise.all([
      this.myPartnerAccountFacade.getMyPartnerAccountDetails$(),
      this.myPartnerAccountFacade.getMyPointsSum$(),
    ]);

    this.partnerCode = partnerCode;
    this.sumOfPartnerPoints = sumOfPartnerPoints;
    if (sumOfPartnerPoints?.GRANTED > 0) {
      this.myPartnerAccountFacade.isPayoutAvailable();
    }
  }

  private animateCircles(): void {
    this.circle1Element.nativeElement.classList.add('zoomed-in');
    setTimeout(() => this.circle1Element?.nativeElement.classList.remove('zoomed-in'), 300);

    setTimeout(() => this.circle2Element?.nativeElement.classList.add('zoomed-in'), 400);
    setTimeout(() => this.circle2Element?.nativeElement.classList.remove('zoomed-in'), 700);

    setTimeout(() => this.circle3Element?.nativeElement.classList.add('zoomed-in'), 800);
    setTimeout(() => this.circle3Element?.nativeElement.classList.remove('zoomed-in'), 1100);
  }

  public copyToClipboard(text: string): void {
    if (!text?.length || !text.trim().length) {
      this.snackService.showError('COMMON.CANNOT_COPY_EMPTY_TEXT_TO_CLIPBOARD');
      return;
    }

    const success = this.clipboard.copy(text);
    let trimmedText = text.trim();
    if (trimmedText.length > 20) {
      trimmedText = `${text.slice(0, 20)}...`;
    }

    if (success) {
      this.snackService.showInfo('COMMON.COPIED_TO_CLIPBOARD', { text: trimmedText });
    }
    if (!success) {
      this.snackService.showError('COMMON.UNABLE_TO_COPY_TO_CLIPBOARD', { text: trimmedText });
    }
  }

  public onCategoryHover(category: PARTNER_POINTS_STATUS): void {
    setTimeout(() => {
      this.hoveredOnSumCategory = {
        categoryName: category,
        pointsSum: this.sumOfPartnerPoints[category],
      };
    }, 150);
  }

  public openPayoutRequestModal(): void {
    this.payoutRequestModal
      .open({
        userName: this.userName,
        userPoints: this.sumOfPartnerPoints.GRANTED,
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(async requestMade => {
        if (!requestMade) {
          // do nothing
          return;
        }

        // refresh all data
        this.loadingView = true;
        await this.initData();
        this.loadingView = false;
      });
  }

  public openPointsHistoryDrawer(): void {
    this.payoutsHistoryLoading = true;
    this.payoutsHistoryDrawerVisible = true;

    this.payoutsHistory$.pipe(skip(1), take(1), takeUntil(this.destroy$)).subscribe(h => {
      setTimeout(() => (this.payoutsHistoryLoading = false), 1000);
    });

    this.myPartnerAccountFacade.getMyPayoutsHistory();
  }

  ngOnDestroy(): void {
    this.drawerRef.close();
    this.destroy$.next(true);
    this.destroy$.complete();
  }

  public close(): void {
    this.drawerRef.close();
  }
}
