import { Injectable } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';

import { DeviceInfoService } from '@shared/device-info/device-info.service';
import { PayoutRequestModalComponent } from './payout-request-modal.component';

export interface PayoutRequestModalOpts {
  userName: string;
  userPoints: number;
}

@Injectable({
  providedIn: 'root',
})
export class PayoutRequestModalService {
  constructor(
    private dialog: MatDialog,
    private readonly deviceInfoService: DeviceInfoService
  ) {}

  public open(opts: PayoutRequestModalOpts): MatDialogRef<PayoutRequestModalComponent> {
    const deviceInfo = this.deviceInfoService.getInfo();
    let dialogWidth = '650px';
    let minHeight = '450px';

    if (deviceInfo.deviceTypeDetected !== 'DESKTOP') {
      dialogWidth = '95vw';
      minHeight = '95dvh';
    }

    return this.dialog.open(PayoutRequestModalComponent, {
      autoFocus: true,
      width: dialogWidth,
      maxWidth: dialogWidth,
      minHeight,
      data: { ...opts },
      disableClose: true,
    });
  }
}
