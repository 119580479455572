import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@environment';
import { DiscountCode, PartnerPointsPayout, RequestPayoutOpts, SumOfPartnerPoints } from '@interfaces';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class MyPartnerAccountHttpService {
  private API_URL = environment.API_URL;
  private FULL_URL = `${this.API_URL}/my-partner-account`;

  constructor(private http: HttpClient) {}

  public getMyPartnerAccountDetails(): Observable<Partial<DiscountCode>> {
    const url = `${this.FULL_URL}`;
    return this.http.get<Partial<DiscountCode>>(url);
  }

  public getMyPartnerPoints(): Observable<SumOfPartnerPoints> {
    const url = `${this.FULL_URL}/points`;
    return this.http.get<SumOfPartnerPoints>(url);
  }

  public payoutAvailable(): Observable<boolean> {
    const url = `${this.FULL_URL}/payout-available`;
    return this.http.get<boolean>(url);
  }

  public requestPayout(opts: RequestPayoutOpts): Observable<PartnerPointsPayout> {
    const url = `${this.FULL_URL}/payout-request`;
    return this.http.post<PartnerPointsPayout>(url, opts);
  }

  public getMyPayoutsHistory(): Observable<Partial<PartnerPointsPayout>[]> {
    const url = `${this.FULL_URL}/payouts-history`;
    return this.http.get<Partial<PartnerPointsPayout>[]>(url);
  }
}
