import { DISCOUNT_CODE_TYPE, MultiLangField, PARTNER_TERMS_CONFIG, User } from '@interfaces';
import { Pagination } from './http';

export interface SearchPartnersOpts extends Pagination {
  query?: string;
  partnerTermsConfig?: PARTNER_TERMS_CONFIG;
}

export interface PartnerInView extends Partial<User> {
  pointsGranted: number;
  hasActivePayout: boolean;
}

export interface UpdateGlobalTermsOpts {
  discountType: DISCOUNT_CODE_TYPE;
  discountAmount?: number;
  discountPercentage?: number;
  pointsRatio: number;
  slogan: MultiLangField;
}
