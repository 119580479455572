import { createFeatureSelector, createSelector } from '@ngrx/store';
import { PartnerCodesState } from './partner-codes.state';

export const selectDcState = createFeatureSelector<PartnerCodesState>('partnerCodes');

export const selectLoading = createSelector(selectDcState, state => state.loading);
export const selectLoadingList = createSelector(selectDcState, state => state.loadingList);
export const selectGlobalTerms = createSelector(selectDcState, state => state.globalTerms);
export const selectPartnerCodeDetails = createSelector(selectDcState, state => state.partnerCodeDetails);
export const selectCodeGenerationInProgress = createSelector(selectDcState, state => state.codeGenerationInProgress);
export const selectSumOfPartnerPoints = createSelector(selectDcState, state => state.sumOfPartnerPoints);
export const selectPartnerCodeUsage = createSelector(selectDcState, state => state.partnerCodeUsage);
export const selectPartnerPayouts = createSelector(selectDcState, state => state.partnerPayouts);
export const selectAllPartnerPoints = createSelector(selectDcState, state => state.allPartnerPoints);

export const selectPartners = createSelector(selectDcState, state => state.partners);
export const selectPartnersPagination = createSelector(selectDcState, state => state.partnersPagination);

export const selectPayouts = createSelector(selectDcState, state => state.payouts);
export const selectPayoutsPagination = createSelector(selectDcState, state => state.payoutsPagination);
