import { CommonModule, registerLocaleData } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatMomentDateModule } from '@angular/material-moment-adapter';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';

import { HttpClient } from '@angular/common/http';
import { NgSelectModule } from '@ng-select/ng-select';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { NgxMaskModule } from 'ngx-mask';

import en from '@angular/common/locales/en';
import pl from '@angular/common/locales/pl';
import ru from '@angular/common/locales/ru';
import uk from '@angular/common/locales/uk';
import { fullDateFormat } from '@constants';
import { NZ_DATE_CONFIG, NZ_I18N, pl_PL } from 'ng-zorro-antd/i18n';

import { MAT_DATE_FORMATS, MAT_RIPPLE_GLOBAL_OPTIONS, RippleGlobalOptions } from '@angular/material/core';
import { ChatsWrapperDesktopComponent } from '@shared/chats-wrapper/chats-wrapper-desktop/chats-wrapper-desktop.component';
import { ChatsWrapperMobileComponent } from '@shared/chats-wrapper/chats-wrapper-mobile/chats-wrapper-mobile.component';
import { CompanyLogoComponent } from '@shared/company-logo/company-logo.component';
import { CountrySelectComponent } from '@shared/country-select/country-select.component';
import { AutoFocusDirective } from '@shared/directives/auto-focus.directive';
import { SwipeDirective } from '@shared/directives/swipe.directive';
import { UserDetailsLinkDirective } from '@shared/directives/user-details-link.directive';
import { PaidTagComponent } from '@shared/paid-tags/paid-tag.component';
import { CountriesVisitsFormLongResiComponent } from '@shared/personal-data-form/LONG_RESI/countries-visits-form-long-resi/countries-visits-form-long-resi.component';
import { FamilyMembersFormLongResiComponent } from '@shared/personal-data-form/LONG_RESI/family-members-form-long-resi/family-members-form-long-resi.component';
import { PreviousVisitsFormLongResiComponent } from '@shared/personal-data-form/LONG_RESI/previous-visits-form-long-resi/previous-visits-form-long-resi.component';
import { CountriesVisitsFormPermStayComponent } from '@shared/personal-data-form/PERM_STAY/countries-visits-form-perm-stay/countries-visits-form-perm-stay.component';
import { PermStayFormComponent } from '@shared/personal-data-form/PERM_STAY/perm-stay-form.component';
import { PermStayStep1Component } from '@shared/personal-data-form/PERM_STAY/perm-stay-step1/perm-stay-step1.component';
import { PermStayStep2Component } from '@shared/personal-data-form/PERM_STAY/perm-stay-step2/perm-stay-step2.component';
import { PermStayStep3Component } from '@shared/personal-data-form/PERM_STAY/perm-stay-step3/perm-stay-step3.component';
import { PermStayStep4Component } from '@shared/personal-data-form/PERM_STAY/perm-stay-step4/perm-stay-step4.component';
import { PreviousVisitsFormPermStayComponent } from '@shared/personal-data-form/PERM_STAY/previous-visits-form-perm-stay/previous-visits-form-perm-stay.component';
import { CountriesVisitsFormTempPremitComponent } from '@shared/personal-data-form/TEMP_PREMIT/countries-visits-form-temp-premit/countries-visits-form-temp-premit.component';
import { FamilyMembersFormTempPremitComponent } from '@shared/personal-data-form/TEMP_PREMIT/family-members-form-temp-premit/family-members-form-temp-premit.component';
import { PreviousVisitsFormTempPremitComponent } from '@shared/personal-data-form/TEMP_PREMIT/previous-visits-form-temp-premit/previous-visits-form-temp-premit.component';
import { ErrorsFormatingAndTranslatePipe } from '@shared/pipes/errors-formating-and-translate.pipe';
import { SafeHtmlPipe } from '@shared/pipes/safe-html.pipe';
import { ServiceLevelTagComponent } from '@shared/purchase-tags/service-level-tag.component';
import { SupportChatWindowDesktopComponent } from '@shared/support-chat-window/support-chat-window-desktop/support-chat-window-desktop.component';
import { SupportChatWindowMobileComponent } from '@shared/support-chat-window/support-chat-window-mobile/support-chat-window-mobile.component';
import { SupportChatDesktopComponent } from '@shared/support-chat/support-chat-desktop/support-chat-desktop.component';
import { SupportChatMobileComponent } from '@shared/support-chat/support-chat-mobile/support-chat-mobile.component';
import { NgxMultipleDatesModule } from 'ngx-multiple-dates';
import { DeleteAccountModalComponent } from '../user-settings/delete-account-modal/delete-account-modal.component';
import { AnnexFormComponent } from './annex/annex-form.component';
import { AnnexComponent } from './annex/annex.component';
import { AssignProcessTypeModalComponent } from './assign-process-type-modal/assign-process-type-modal.component';
import { InsuranceBannerComponent } from './banners/insurance-banner/insurance-banner.component';
import { TranslationsBannerComponent } from './banners/translations-banner/translations-banner.component';
import { BlockingModalComponent } from './blocking-modal/blocking-modal.component';
import { ChangesHistoryModalComponent } from './changes-history-modal/changes-history-modal.component';
import { ConfirmationModalComponent } from './confirmation-modal/confirmation-modal.component';
import { CredentialsModalComponent } from './credentials-modal/credentials-modal.component';
import { CustomTooltipComponent } from './custom-tooltip/custom-tooltip.component';
import { CustomTooltipDirective } from './custom-tooltip/custom-tooltip.directive';
import { DatepickerHeaderComponent } from './datepicker-header/datepicker-header.component';
import { MatOptionHeightDirective } from './directives/mat-option-height-directive';
import { RequiredPermsDirective } from './directives/required-perms.directive';
import { SetTabIndexDirective } from './directives/set-tabindex.directive';
import { DiscussionModalComponent } from './discussion-modal/discussion-modal.component';
import { InstructionsPersonalizationModalComponent } from './instructions-personalization-modal/instructions-personalization-modal.component';
import { IntroModalComponent } from './intro-modal/intro-modal.component';
import { ModeChangedModalComponent } from './mode-changed-modal/mode-changed-modal.component';
import { MOSAnnexAddressComponent } from './mos-annex-address/mos-annex-address.component';
import { MyPartnerAccountDrawerComponent } from './my-partner-account-drawer/my-partner-account-drawer.component';
import { PayoutRequestModalComponent } from './my-partner-account-drawer/payout-request-modal/payout-request-modal.component';
import { NgZorroExportsModule } from './ngZorroExports.module';
import { OfficeVisitDetailsModalComponent } from './office-visit-details-modal/office-visit-details-modal.component';
import { AccountGeneralInfoComponent } from './partner-account-drawer/account-general-info/account-general-info.component';
import { CodeUsageComponent } from './partner-account-drawer/code-usage/code-usage.component';
import { PartnerAccountDrawerComponent } from './partner-account-drawer/partner-account-drawer.component';
import { PartnerAllPointsComponent } from './partner-account-drawer/partner-all-points/partner-all-points.component';
import { PartnerCodeFormComponent } from './partner-account-drawer/partner-code-form/partner-code-form.component';
import { PartnerPayoutsHistoryComponent } from './partner-account-drawer/partner-payouts-history/partner-payouts-history.component';
import { LongResiFormComponent } from './personal-data-form/LONG_RESI/long-resi-form.component';
import { LongResiStep1Component } from './personal-data-form/LONG_RESI/long-resi-step1/long-resi-step1.component';
import { LongResiStep2Component } from './personal-data-form/LONG_RESI/long-resi-step2/long-resi-step2.component';
import { LongResiStep3Component } from './personal-data-form/LONG_RESI/long-resi-step3/long-resi-step3.component';
import { LongResiStep4Component } from './personal-data-form/LONG_RESI/long-resi-step4/long-resi-step4.component';
import { LongResiStep5Component } from './personal-data-form/LONG_RESI/long-resi-step5/long-resi-step5.component';
import { LongResiStep6Component } from './personal-data-form/LONG_RESI/long-resi-step6/long-resi-step6.component';
import { FamilyMembersFormPermStayComponent } from './personal-data-form/PERM_STAY/family-members-form-perm-stay/family-members-form-perm-stay.component';
import { PermStayStep5Component } from './personal-data-form/PERM_STAY/perm-stay-step5/perm-stay-step5.component';
import { PermStayStep6Component } from './personal-data-form/PERM_STAY/perm-stay-step6/perm-stay-step6.component';
import { TempPremitFormComponent } from './personal-data-form/TEMP_PREMIT/temp-premit-form.component';
import { TempPremitStep1Component } from './personal-data-form/TEMP_PREMIT/temp-premit-step1/temp-premit-step1.component';
import { TempPremitStep2Component } from './personal-data-form/TEMP_PREMIT/temp-premit-step2/temp-premit-step2.component';
import { TempPremitStep3Component } from './personal-data-form/TEMP_PREMIT/temp-premit-step3/temp-premit-step3.component';
import { TempPremitStep4Component } from './personal-data-form/TEMP_PREMIT/temp-premit-step4/temp-premit-step4.component';
import { TempPremitStep5Component } from './personal-data-form/TEMP_PREMIT/temp-premit-step5/temp-premit-step5.component';
import { NationalitySelectComponent } from './personal-data-form/nationality-select/nationality-select.component';
import { PersonalDataFormComponent } from './personal-data-form/personal-data-form.component';
import { CustomDatePipe } from './pipes/custom-date.pipe';
import { FilterListPipe } from './pipes/filter-list.pipe';
import { LinebreaksPipe } from './pipes/linebreaks.pipe';
import { OrderByPipe } from './pipes/order-by.pipe';
import { SanitizeHtmlPipe } from './pipes/sanitize-html.pipe';
import { TruncatePipe } from './pipes/truncate.pipe';
import { SafeUrlPipe } from '@shared/pipes/safe-url.pipe';
import { PurchaseProductCardComponent } from './purchase-product-card/purchase-product-card.component';
import { RefreshTokenModalComponent } from './refresh-token-modal/refresh-token-modal.component';
import { ReviewItemsModalComponent } from './review-items-modal/review-items-modal.component';
import { SnackBarComponent } from './snack-bar/snack-bar.component';
import { SwitchOptionPillsComponent } from './switch-option-pills/switch-option-pills.component';
import { TimePickerModalComponent } from './time-picker-modal/time-picker-modal.component';
import { UserProcessStatusComponent } from './user-process-status/user-process-status.component';
import { UtilsButtonsComponent } from './utils-buttons/utils-buttons.component';
import { VisitDateModalComponent } from './visit-date-modal/visit-date-modal.component';

registerLocaleData(pl);
registerLocaleData(en);
registerLocaleData(uk);
registerLocaleData(ru);

export function createTranslateLoader(http: HttpClient): TranslateHttpLoader {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

const commonModules = [CommonModule, NgSelectModule, FormsModule, ReactiveFormsModule];

const materialModules = [
  MatFormFieldModule,
  MatInputModule,
  MatButtonModule,
  MatMenuModule,
  MatIconModule,
  MatProgressSpinnerModule,
  MatTableModule,
  MatSnackBarModule,
  MatSortModule,
  MatSelectModule,
  MatPaginatorModule,
  MatStepperModule,
  MatRadioModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatMomentDateModule,
  MatTabsModule,
  MatDialogModule,
  MatExpansionModule,
  MatBadgeModule,
  MatChipsModule,
  MatProgressBarModule,
  MatCheckboxModule,
  MatButtonToggleModule,
  MatCardModule,
  MatSlideToggleModule,
];

const components = [
  CustomTooltipComponent,
  FamilyMembersFormTempPremitComponent,
  FamilyMembersFormLongResiComponent,
  FamilyMembersFormPermStayComponent,
  CountrySelectComponent,
  CountriesVisitsFormTempPremitComponent,
  CountriesVisitsFormLongResiComponent,
  PreviousVisitsFormTempPremitComponent,
  PreviousVisitsFormLongResiComponent,
  PersonalDataFormComponent,
  TempPremitFormComponent,
  TempPremitStep1Component,
  TempPremitStep2Component,
  TempPremitStep3Component,
  TempPremitStep4Component,
  TempPremitStep5Component,
  LongResiFormComponent,
  LongResiStep1Component,
  LongResiStep2Component,
  LongResiStep3Component,
  LongResiStep4Component,
  LongResiStep5Component,
  PermStayFormComponent,
  PermStayStep1Component,
  PermStayStep2Component,
  PermStayStep3Component,
  PermStayStep4Component,
  PermStayStep5Component,
  PermStayStep6Component,
  CountriesVisitsFormPermStayComponent,
  PreviousVisitsFormPermStayComponent,
  LongResiStep6Component,
  UtilsButtonsComponent,
  DatepickerHeaderComponent,
  SnackBarComponent,
  SwitchOptionPillsComponent,
  AnnexComponent,
  AnnexFormComponent,
  MOSAnnexAddressComponent,
  ChatsWrapperDesktopComponent,
  ChatsWrapperMobileComponent,
  SupportChatDesktopComponent,
  SupportChatMobileComponent,
  SupportChatWindowMobileComponent,
  SupportChatWindowDesktopComponent,
  PurchaseProductCardComponent,
  NationalitySelectComponent,
  CompanyLogoComponent,
  DeleteAccountModalComponent,
  UserProcessStatusComponent,
  ServiceLevelTagComponent,
  PaidTagComponent,
  InsuranceBannerComponent,
  TranslationsBannerComponent,
  PartnerAccountDrawerComponent,
  PartnerCodeFormComponent,
  AccountGeneralInfoComponent,
  CodeUsageComponent,
  MyPartnerAccountDrawerComponent,
  PayoutRequestModalComponent,
  PartnerPayoutsHistoryComponent,
  PartnerAllPointsComponent,
];

const modals = [
  DiscussionModalComponent,
  ConfirmationModalComponent,
  ChangesHistoryModalComponent,
  VisitDateModalComponent,
  IntroModalComponent,
  RefreshTokenModalComponent,
  OfficeVisitDetailsModalComponent,
  TimePickerModalComponent,
  ModeChangedModalComponent,
  InstructionsPersonalizationModalComponent,
  CredentialsModalComponent,
  ReviewItemsModalComponent,
  BlockingModalComponent,
  AssignProcessTypeModalComponent,
];

const pipes = [
  TruncatePipe,
  SanitizeHtmlPipe,
  SafeHtmlPipe,
  SafeUrlPipe,
  LinebreaksPipe,
  OrderByPipe,
  FilterListPipe,
  ErrorsFormatingAndTranslatePipe,
  CustomDatePipe,
];

const directives = [
  CustomTooltipDirective,
  MatOptionHeightDirective,
  SetTabIndexDirective,
  AutoFocusDirective,
  SwipeDirective,
  UserDetailsLinkDirective,
  RequiredPermsDirective,
];

export const MY_FORMATS = {
  parse: {
    dateInput: fullDateFormat,
  },
  display: {
    dateInput: fullDateFormat,
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

const globalRippleConfig: RippleGlobalOptions = {
  disabled: true,
  animation: {
    enterDuration: 300,
    exitDuration: 0,
  },
};

@NgModule({
  declarations: [...components, ...directives, ...modals, ...pipes],
  imports: [
    ...commonModules,
    ...materialModules,
    NgZorroExportsModule,
    RouterModule,
    NgxMultipleDatesModule,
    NgxMaskModule.forChild(),
    TranslateModule.forChild({
      defaultLanguage: 'pl',
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpClient],
      },
    }),
  ],
  providers: [
    { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
    { provide: MAT_RIPPLE_GLOBAL_OPTIONS, useValue: globalRippleConfig },
    { provide: NZ_I18N, useValue: pl_PL },
    { provide: NZ_DATE_CONFIG, useValue: { firstDayOfWeek: 1 } },
  ],
  exports: [
    ...commonModules,
    ...materialModules,
    NgZorroExportsModule,
    TranslateModule,
    NgxMultipleDatesModule,
    NgxMaskModule,
    ...components,
    ...directives,
    ...pipes,
  ],
})
export class SharedModule {}
