export enum PARTNER_POINTS_STATUS {
  PENDING_VERIFICATION = 'PENDING_VERIFICATION',
  GRANTED = 'GRANTED',
  REVOKED = 'REVOKED',
  PENDING_PAYOUT = 'PENDING_PAYOUT',
  PAID_OUT = 'PAID_OUT',
}

export enum PARTNER_POINTS_SOURCE {
  'ADDED_MANUALLY' = 'ADDED_MANUALLY',
  'PARTNER_CODE_USED' = 'PARTNER_CODE_USED',
}

export type SumOfPartnerPoints = {
  [key in PARTNER_POINTS_STATUS]: number;
};

export interface ChangePointsStatusOpts {
  userId: string;
  pointsId: string;
  status: PARTNER_POINTS_STATUS;
}

export interface AddPartnerPointsOpts {
  userId: string;
  quantity: number;
}

export interface RemovePartnerPointsOpts {
  partnerId: string;
  pointsId: string;
}
