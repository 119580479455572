import { NzDrawerRef } from 'ng-zorro-antd/drawer';

import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { PARTNER_POINTS_SOURCE, PARTNER_POINTS_STATUS, PartnerPoints } from '@interfaces';
import { TranslateService } from '@ngx-translate/core';
import { ConfirmationModalService } from '@shared/confirmation-modal/confirmation-modal.service';
import { PartnerCodesFacade } from '@state/partner-codes';
import { UserAuthFacade } from '@state/user-auth';
import { isSafeInteger } from 'lodash-es';
import * as moment from 'moment-timezone';
import { Observable, ReplaySubject, take, takeUntil } from 'rxjs';

@Component({
  selector: 'app-partner-all-points',
  templateUrl: './partner-all-points.component.html',
  styleUrls: ['./partner-all-points.component.scss'],
})
export class PartnerAllPointsComponent implements OnInit, OnDestroy {
  private readonly destroy$: ReplaySubject<boolean> = new ReplaySubject(1);

  @Input() userId: string;
  @Input() userName: string;

  public loading$ = this.partnerCodesFacade.loading$;
  public points$: Observable<PartnerPoints[]> = this.partnerCodesFacade.allPartnerPoints$;

  public PARTNER_POINTS_STATUS = PARTNER_POINTS_STATUS;
  public PARTNER_POINTS_SOURCE = PARTNER_POINTS_SOURCE;

  public pointsToAdd: number = null;
  public addingPointsFormVisible = false;
  public isSafeInteger = isSafeInteger;

  constructor(
    private readonly drawerRef: NzDrawerRef<any>,
    private readonly userAuthFacade: UserAuthFacade,
    private readonly partnerCodesFacade: PartnerCodesFacade,
    private readonly confirmation: ConfirmationModalService,
    private readonly translateService: TranslateService
  ) {
    moment.tz.setDefault('Europe/Warsaw');
  }

  public async ngOnInit(): Promise<void> {
    if (!this.userId?.length) {
      console.error('INVALID USER ID PROVIDED FOR DRAWER COMPONENT');
      this.drawerRef.close();
      return;
    }

    this.initPartnerPointsData();
  }

  public async initPartnerPointsData(): Promise<void> {
    this.partnerCodesFacade.getAllPartnerPoints(this.userId);
  }

  public changePointsStatus(points: PartnerPoints, status: PARTNER_POINTS_STATUS): void {
    const statusTranslated = this.translateService.instant('MANAGEMENT.PARTNER.POINTS_STATUS.' + status);
    this.confirmation
      .open({
        confirmationToTranslate: 'MANAGEMENT.PARTNER.ARE_YOU_SURE_CHANGE_STATUS_TO',
        translateParams: { newStatus: statusTranslated },
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.partnerCodesFacade.changePointsStatus({
          pointsId: points.id,
          userId: this.userId,
          status,
        });
      });
  }

  public togglePointsAdding(): void {
    this.pointsToAdd = null;
    this.addingPointsFormVisible = !this.addingPointsFormVisible;
  }

  public addPoints(): void {
    if (!isSafeInteger(this.pointsToAdd) || this.pointsToAdd <= 0 || this.pointsToAdd >= 99999) {
      return;
    }

    this.confirmation
      .open({
        confirmationToTranslate: 'MANAGEMENT.PARTNER.ARE_YOU_SURE_ADD_POINTS',
        translateParams: { points: this.pointsToAdd, userName: this.userName },
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.partnerCodesFacade.addPartnerPointsManually({ quantity: this.pointsToAdd, userId: this.userId });
        setTimeout(() => this.togglePointsAdding(), 300);
      });
  }

  public removePoints(points: PartnerPoints): void {
    this.confirmation
      .open({
        confirmationToTranslate: 'MANAGEMENT.PARTNER.ARE_YOU_SURE_REMOVE_POINTS',
        translateParams: { points: points.quantity },
      })
      .afterClosed()
      .pipe(take(1), takeUntil(this.destroy$))
      .subscribe(res => {
        if (!res) {
          return;
        }

        this.partnerCodesFacade.removePartnerPoints({ partnerId: this.userId, pointsId: points.id });
      });
  }

  public ngOnDestroy(): void {
    this.destroy$.next(true);
    this.destroy$.complete();
  }
}
