import { hasEmployeeUnreadCommentsInProcess } from '@core/document-comments-utils';
import { Pagination } from '@interfaces';
import { Action, createReducer, on } from '@ngrx/store';
import * as commentsActions from '@state/comments/comments.actions';
import * as instructionItemsActions from '@state/instruction-items/instruction-items.actions';
import * as userProcessActions from '@state/user-process/user-process.actions';
import * as managementActions from './management.actions';
import { ManagementState, defaultPagination, initialState } from './management.state';

const reducer = createReducer(
  initialState,
  on(userProcessActions.downloadFile, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(userProcessActions.downloadFileSuccess, state => {
    return {
      ...state,
      loading: false,
      errorMessage: null,
    };
  }),
  on(userProcessActions.downloadFileError, state => ({ ...state, loading: false })),
  on(managementActions.searchUsersProcesses, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(managementActions.searchUsersProcessesSuccess, (state, { processes }) => ({
    ...state,
    usersProcesses: processes.data,
    usersProcessesPagination: processes.pagination,
    loading: false,
    errorMessage: null,
  })),
  on(managementActions.searchUsersProcessesError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(managementActions.searchProcessesOfUser, state => ({
    ...state,
    loading: true,
    processesOfUser: null,
    processesOfUserPagination: { ...(defaultPagination as Pagination) },
    errorMessage: null,
  })),
  on(managementActions.searchProcessesOfUserSuccess, (state, { processesOfUser }) => ({
    ...state,
    processesOfUser: processesOfUser.data,
    processesOfUserPagination: processesOfUser.pagination,
    loading: false,
    errorMessage: null,
  })),
  on(managementActions.searchProcessesOfUserError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(managementActions.getUserProcessBasicData, state => ({
    ...state,
    userProcessBasicData: null,
    loadingBasicData: true,
    errorMessage: null,
  })),
  on(managementActions.getUserProcessBasicDataSuccess, (state, { userProcessBasicData }) => ({
    ...state,
    userProcessBasicData,
    loadingBasicData: false,
    errorMessage: null,
  })),
  on(managementActions.getUserProcessBasicDataError, (state, { errorMessage }) => ({
    ...state,
    userProcessBasicData: null,
    loadingBasicData: false,
    errorMessage,
  })),

  on(managementActions.addNewUserProcess, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(managementActions.addNewUserProcessSuccess, (state, { newUserProcess }) => ({
    ...state,
    loading: false,
    user: {
      ...state.user,
      userProcesses: state.user?.userProcesses?.length
        ? [...state.user.userProcesses, newUserProcess]
        : [newUserProcess],
    },
    errorMessage: null,
  })),
  on(managementActions.addNewUserProcessError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  })),

  on(managementActions.removeUserProcess, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(managementActions.removeUserProcessSuccess, (state, { removedUserProcessId }) => ({
    ...state,
    loading: false,
    user: {
      ...state.user,
      userProcesses: state.user.userProcesses.filter(({ id }) => id !== removedUserProcessId),
    },
    errorMessage: null,
  })),
  on(managementActions.removeUserProcessError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  })),

  on(managementActions.restoreUserProcess, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(managementActions.restoreUserProcessSuccess, (state, { updatedUserProcess }) => ({
    ...state,
    loading: false,
    processesOfUser: state.processesOfUser.map(upInState => {
      return upInState.id === updatedUserProcess.id ? updatedUserProcess : upInState;
    }),
    user: {
      ...state.user,
      userProcesses: state.user.userProcesses.map(upInState => {
        return upInState.id === updatedUserProcess.id ? updatedUserProcess : upInState;
      }),
    },
    errorMessage: null,
  })),
  on(managementActions.restoreUserProcessError, (state, { errorMessage }) => ({
    ...state,
    loading: false,
    errorMessage,
  })),

  on(managementActions.getUser, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(managementActions.getUserSuccess, (state, { user }) => ({
    ...state,
    user,
    processesOfUser: null,
    processesOfUserPagination: { ...(defaultPagination as Pagination) },
    loading: false,
    errorMessage: null,
  })),
  on(managementActions.getUserError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
    processesOfUser: null,
    processesOfUserPagination: { ...(defaultPagination as Pagination) },
  })),

  on(managementActions.getUserProcess, state => ({
    ...state,
    loading: true,
    userProcess: null,
    errorMessage: null,
  })),
  on(managementActions.getUserProcessSuccess, (state, { userProcess }) => ({
    ...state,
    userProcess,
    loading: false,
    errorMessage: null,
  })),
  on(managementActions.getUserProcessError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(managementActions.updateUserProcessBasicData, managementActions.updateUserProcessServices, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(
    managementActions.updateUserProcessBasicDataSuccess,
    managementActions.updateUserProcessServicesSuccess,
    (state, { updatedUserProcess }) => ({
      ...state,
      userProcess: updatedUserProcess,
      loading: false,
      errorMessage: null,
    })
  ),
  on(
    managementActions.updateUserProcessBasicDataError,
    managementActions.updateUserProcessServicesError,
    (state, { errorMessage }) => ({
      ...state,
      loading: false,
      errorMessage,
    })
  ),

  on(managementActions.getUserProcessDocuments, state => ({
    ...state,
    loadingDocuments: true,
    errorMessage: null,
  })),
  on(managementActions.getUserProcessDocumentsSuccess, (state, { processDocuments, userProcessId }) => ({
    ...state,
    userProcessDocuments: {
      ...state.userProcessDocuments,
      userProcessId,
      processDocumentsList: processDocuments,
    },
    loadingDocuments: false,
    errorMessage: null,
  })),
  on(managementActions.getUserProcessDocumentsError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loadingDocuments: false,
  })),

  on(managementActions.updatePersonalDetails, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(managementActions.updatePersonalDetailsSuccess, (state, { updatedUserProcess }) => ({
    ...state,
    userProcess: updatedUserProcess,
    usersProcesses: state.usersProcesses?.map(userProcess =>
      userProcess.id === updatedUserProcess.id ? updatedUserProcess : userProcess
    ),
    processesOfUser: state.processesOfUser?.map(userProcess =>
      userProcess.id === updatedUserProcess.id ? updatedUserProcess : userProcess
    ),
    loading: false,
    errorMessage: null,
  })),

  on(managementActions.assignProcessType, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(managementActions.assignProcessTypeSuccess, (state, { userProcess: updatedUserProcess }) => ({
    ...state,
    userProcess: updatedUserProcess,
    usersProcesses: state.usersProcesses?.map(userProcessInstate =>
      userProcessInstate.id === updatedUserProcess.id ? updatedUserProcess : userProcessInstate
    ),
    processesOfUser: state.processesOfUser?.map(userProcessInState =>
      userProcessInState.id === updatedUserProcess.id ? updatedUserProcess : userProcessInState
    ),
    loading: false,
    errorMessage: null,
  })),
  on(managementActions.assignProcessTypeError, state => ({
    ...state,
    loading: false,
    errorMessage: 'ERROR_ASSIGNING_PROCESS_TYPE',
  })),

  on(managementActions.getDetailsValidationConfig, state => ({
    ...state,
    loading: true,
    errorMessage: null,
    personalDetailsValidationConfig: null,
  })),
  on(managementActions.getDetailsValidationConfigSuccess, (state, { userProcessId, all, members }) => ({
    ...state,
    loading: false,
    errorMessage: null,
    personalDetailsValidationConfig: { all, members, userProcessId },
  })),
  on(managementActions.getDetailsValidationConfigError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(managementActions.setPersonalDetailsVerification, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),

  on(managementActions.setPersonalDetailsVerificationSuccess, (state, { updatedUserProcess }) => ({
    ...state,
    userProcess: updatedUserProcess,
    loading: false,
    errorMessage: null,
  })),
  on(
    managementActions.updatePersonalDetailsError,
    managementActions.setPersonalDetailsVerificationError,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
      loading: false,
    })
  ),

  on(managementActions.changeProcessType, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(managementActions.changeProcessTypeSuccess, (state, { updatedUserProcess }) => ({
    ...state,
    userProcess: { ...updatedUserProcess },
    loading: false,
    errorMessage: null,
  })),
  on(managementActions.changeProcessTypeError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(managementActions.changeProcessStatus, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(managementActions.changeProcessStatusSuccess, (state, { updatedUserProcess }) => ({
    ...state,
    userProcess: { ...updatedUserProcess },
    loading: false,
    errorMessage: null,
  })),
  on(managementActions.changeProcessStatusError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(managementActions.setUserDocumentStatus, managementActions.updateForeignerDocumentQuestionAnswer, state => ({
    ...state,
    loadingDocuments: true,
    errorMessage: null,
  })),
  on(managementActions.setUserDocumentStatusSuccess, (state, { updatedUserDocument }) => ({
    ...state,
    userProcessDocuments: {
      ...state.userProcessDocuments,
      processDocumentsList: {
        ...state.userProcessDocuments.processDocumentsList,
        userDocs: state.userProcessDocuments.processDocumentsList.userDocs.map(userDoc => {
          return userDoc.id === updatedUserDocument.id ? updatedUserDocument : userDoc;
        }),
      },
    },
    loadingDocuments: false,
    errorMessage: null,
  })),
  on(
    managementActions.setUserDocumentStatusError,
    managementActions.updateForeignerDocumentQuestionAnswerError,
    (state, { errorMessage }) => ({
      ...state,
      errorMessage,
      loadingDocuments: false,
    })
  ),

  on(managementActions.updateForeignerDocumentQuestionAnswerSuccess, (state, { updatedAnswer }) => ({
    ...state,
    userProcessDocuments: {
      ...state.userProcessDocuments,
      processDocumentsList: {
        ...state.userProcessDocuments.processDocumentsList,
        userDocs: state.userProcessDocuments.processDocumentsList.userDocs.map(userDoc => {
          if (userDoc.id === updatedAnswer.userDocumentId) {
            return {
              ...userDoc,
              userDocumentAnswers: userDoc.userDocumentAnswers.map(userDocAnswer => {
                return userDocAnswer.id === updatedAnswer.id
                  ? { ...userDocAnswer, answer: updatedAnswer.answer }
                  : userDocAnswer;
              }),
            };
          }
          return userDoc;
        }),
      },
    },
    loadingDocuments: false,
    errorMessage: null,
  })),

  on(managementActions.getDocumentsGenerationStatus, state => ({
    ...state,
    loadingDocuments: true,
    errorMessage: null,
  })),
  on(managementActions.getDocumentsGenerationStatusSuccess, (state, { status, userProcessId }) => ({
    ...state,
    loadingDocuments: false,
    errorMessage: null,
    userProcessDocuments: {
      ...state.userProcessDocuments,
      userProcessId,
      documentsGenerationStatus: status,
    },
  })),
  on(managementActions.getDocumentsGenerationStatusError, (state, { errorMessage }) => ({
    ...state,
    loadingDocuments: false,
    errorMessage,
  })),

  on(commentsActions.markDocumentCommentsAsReadSuccess, (state, { userProcessId, userDocumentId, updatedComments }) => {
    if (state.userProcessDocuments.userProcessId !== userProcessId) {
      return { ...state };
    }

    const updatedUserDocs = state.userProcessDocuments.processDocumentsList.userDocs.map(userDoc => {
      return userDoc.id !== userDocumentId ? { ...userDoc } : { ...userDoc, documentComments: updatedComments };
    });

    return {
      ...state,
      userProcessDocuments: {
        ...state.userProcessDocuments,
        processDocumentsList: {
          processDocs: { ...state.userProcessDocuments.processDocumentsList.processDocs },
          userDocs: updatedUserDocs,
        },
      },
    };
  }),
  on(commentsActions.readerAnyNewCommentsForceCheck, (state, { readerId, userProcessId }) => {
    if (state.userProcessDocuments.userProcessId !== userProcessId) {
      return { ...state };
    }

    const userDocs = state.userProcessDocuments?.processDocumentsList?.userDocs;
    if (!userDocs?.length) {
      return { ...state };
    }

    const hasNewMessages = hasEmployeeUnreadCommentsInProcess(readerId, userDocs);
    return {
      ...state,
      userProcess: {
        ...state.userProcess,
        hasNewMessages,
      },
    };
  }),

  on(managementActions.generateDocumentsNow, state => ({
    ...state,
    loadingDocuments: true,
  })),
  on(managementActions.generateDocumentsNowError, state => ({
    ...state,
    loadingDocuments: false,
  })),

  on(managementActions.getMergedFileData, managementActions.areDocsGenerated, state => ({
    ...state,
    loadingFileData: true,
    errorMessage: null,
  })),
  on(managementActions.getMergedFileDataSuccess, managementActions.areDocsGeneratedSuccess, state => ({
    ...state,
    loadingFileData: false,
    errorMessage: null,
  })),
  on(managementActions.getMergedFileDataError, managementActions.areDocsGeneratedError, (state, { errorMessage }) => ({
    ...state,
    loadingFileData: false,
    errorMessage,
  })),

  on(managementActions.printAllDocumentsOfForeinger, state => ({
    ...state,
    loading: true,
    loadingFileData: true,
    errorMessage: null,
  })),
  on(managementActions.printAllDocumentsOfForeingerSuccess, state => ({
    ...state,
    loading: false,
    loadingFileData: false,
    errorMessage: null,
  })),
  on(managementActions.printAllDocumentsOfForeingerError, state => ({
    ...state,
    loading: false,
    loadingFileData: false,
    errorMessage: 'ERROR-TRYING-TO-FETCH-MERGED-PDFS',
  })),

  on(managementActions.sendDataToInpol, state => ({
    ...state,
    loadingDocuments: true,
    errorMessage: null,
  })),
  on(managementActions.sendDataToInpolSuccess, state => ({
    ...state,
    loadingDocuments: false,
    errorMessage: null,
  })),
  on(managementActions.sendDataToInpolError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loadingDocuments: false,
  })),

  on(managementActions.sendDataToMos, state => ({
    ...state,
    loadingDocuments: true,
    errorMessage: null,
  })),
  on(managementActions.sendDataToMosSuccess, state => ({
    ...state,
    loadingDocuments: false,
    errorMessage: null,
  })),
  on(managementActions.sendDataToMosError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loadingDocuments: false,
  })),

  on(managementActions.getUserProcessChanges, state => ({
    ...state,
    loadingUserProcessChanges: true,
    errorMessage: null,
  })),
  on(managementActions.getUserProcessChangesSuccess, (state, { userProcessId, userProcessChanges }) => ({
    ...state,
    loadingUserProcessChanges: false,
    errorMessage: null,
    userProcessChanges: {
      userProcessId,
      changes: userProcessChanges,
    },
  })),
  on(managementActions.getUserProcessChangesError, (state, { errorMessage }) => ({
    ...state,
    loadingUserProcessChanges: false,
    errorMessage,
  })),
  on(managementActions.downloadErrorScreenshot, managementActions.downloadINPOLRecording, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(managementActions.downloadErrorScreenshotSuccess, managementActions.downloadINPOLRecordingSuccess, state => ({
    ...state,
    loading: false,
    errorMessage: null,
  })),
  on(managementActions.downloadErrorScreenshotError, managementActions.downloadINPOLRecordingError, state => ({
    ...state,
    loading: false,
    errorMessage: null,
  })),
  on(managementActions.setVisitDate, managementActions.deleteVisitDate, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(
    managementActions.setVisitDateSuccess,
    managementActions.deleteVisitDateSuccess,
    (state, { updatedUserProcess }) => ({
      ...state,
      loading: false,
      errorMessage: null,
      userProcess: { ...updatedUserProcess },
    })
  ),
  on(managementActions.setVisitDateError, managementActions.deleteVisitDateError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),
  on(managementActions.confirmVoivoChange, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(managementActions.confirmVoivoChangeSuccess, state => ({
    ...state,
    loading: false,
    errorMessage: null,
  })),
  on(managementActions.confirmVoivoChangeError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(managementActions.updateDocEnabled, managementActions.updateDocEnabledBulk, state => ({
    ...state,
    loadingProcess: true,
    errorMessage: null,
  })),
  on(managementActions.updateDocEnabledSuccess, (state, { updatedDoc }) => ({
    ...state,
    loadingProcess: false,
    errorMessage: null,
    // allProcessesWithDocuments: state.allProcessesWithDocuments.map(process => {
    //   return {
    //     ...process,
    //     documents: process.documents.map(doc => (doc.id === updatedDoc.id ? { ...updatedDoc } : { ...doc })),
    //   };
    // }),
  })),
  on(managementActions.updateDocEnabledBulkSuccess, (state, { updatedDocs }) => ({
    ...state,
    loadingProcess: false,
    errorMessage: null,
    // allProcessesWithDocuments: state.allProcessesWithDocuments.map(process => {
    //   const processDocs = process.documents.map(doc => {
    //     const updatedDoc = updatedDocs.find(({ id: updatedDocId }) => updatedDocId === doc.id);
    //     return updatedDoc ? { ...updatedDoc } : { ...doc };
    //   });
    //   return { ...process, documents: processDocs || [] };
    // }),
  })),
  on(managementActions.updateDocEnabledError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loadingAllProcessesWithDocuments: false,
  })),
  on(instructionItemsActions.setUseCustomList, (state, { useCustomList, userProcessId }) => {
    return {
      ...state,
      userProcess:
        state.userProcess.id === userProcessId
          ? { ...state.userProcess, usingCustomInstructionItemsList: useCustomList }
          : state.userProcess,
    };
  }),
  on(instructionItemsActions.setEnabledFeesSuccess, (state, { userProcessId, showFeesOnInstructionItemsList }) => {
    return {
      ...state,
      userProcess:
        state.userProcess.id === userProcessId
          ? { ...state.userProcess, showFeesOnInstructionItemsList }
          : state.userProcess,
    };
  }),

  on(managementActions.getItemsSentForFinalReview, state => ({
    ...state,
    itemsSentForFinalReview: [],
    loadingItemsInReview: true,
  })),
  on(managementActions.getItemsSentForFinalReviewSuccess, (state, { userAssets }) => ({
    ...state,
    itemsSentForFinalReview: userAssets,
    loadingItemsInReview: false,
  })),
  on(managementActions.getItemsSentForFinalReviewError, (state, { errorMessage }) => ({
    ...state,
    loadingItemsInReview: false,
    errorMessage,
  })),
  on(managementActions.downloadItemSentForFinalReview, state => ({
    ...state,
    loading: true,
  })),
  on(managementActions.downloadItemSentForFinalReviewSuccess, (state, {}) => ({
    ...state,
    loading: false,
  })),
  on(managementActions.downloadItemSentForFinalReviewError, (state, {}) => ({
    ...state,
    loading: false,
  })),

  on(managementActions.updateDocumentsReviewComment, state => ({
    ...state,
    loading: true,
    errorMessage: null,
  })),
  on(managementActions.updateDocumentsReviewCommentSuccess, (state, { userProcess }) => ({
    ...state,
    userProcess: { ...userProcess },
    loading: false,
    errorMessage: null,
  })),
  on(managementActions.changeProcessStatusError, (state, { errorMessage }) => ({
    ...state,
    errorMessage,
    loading: false,
  })),

  on(managementActions.sendFollowUpEmail, state => ({
    ...state,
    sendingEmail: true,
  })),
  on(managementActions.sendFollowUpEmailSuccess, state => ({
    ...state,
    sendingEmail: false,
  })),
  on(managementActions.sendFollowUpEmailError, state => ({
    ...state,
    sendingEmail: false,
  })),

  on(managementActions.getProcessWithDocs, state => ({
    ...state,
    loadingProcess: true,
  })),
  on(managementActions.getProcessWithDocsSuccess, (state, { process }) => ({
    ...state,
    processWithDocuments: process,
    loadingProcess: false,
  })),
  on(managementActions.isAnyProcessOfMineMissingAssistant, state => ({
    ...state,
    checkingMissingAssistantInProgress: true,
  })),
  on(managementActions.isAnyProcessOfMineMissingAssistantSuccess, (state, { anyMissing }) => ({
    ...state,
    checkingMissingAssistantInProgress: false,
    anyMyProcessesMissingAssistant: anyMissing,
  })),
  on(managementActions.isAnyProcessOfMineMissingAssistantError, state => ({
    ...state,
    checkingMissingAssistantInProgress: false,
  }))
);

export function managementReducer(state: ManagementState, action: Action): ManagementState {
  return reducer(state, action);
}
