import { Injectable } from '@angular/core';
import { DiscountCode, RequestPayoutOpts, SumOfPartnerPoints } from '@interfaces';
import { Actions, ofType } from '@ngrx/effects';

import { Store } from '@ngrx/store';

import { AppState } from '@state';
import { lastValueFrom, map, take, takeUntil } from 'rxjs';
import * as mpaActions from './my-partner-account.actions';
import * as selectors from './my-partner-account.selectors';

@Injectable()
export class MyPartnerAccountFacade {
  public loading$ = this.store.select(selectors.selectLoading);
  public partnerCodeDetails$ = this.store.select(selectors.selectPartnerCodeDetails);
  public partnerPointsSum$ = this.store.select(selectors.selectSumOfPartnerPoints);
  public payoutAvailable$ = this.store.select(selectors.selectPayoutAvailable);
  public payoutsHistory$ = this.store.select(selectors.selectPayoutsHistory);

  public getMyPartnerAccountDetailsError$ = this.actions.pipe(ofType(mpaActions.getMyPartnerAccountDetailsError));
  public getMyPointsSumError$ = this.actions.pipe(ofType(mpaActions.getMyPointsError));
  public requestPayoutSuccess$ = this.actions.pipe(ofType(mpaActions.requestPayoutSuccess));
  public requestPayoutError$ = this.actions.pipe(ofType(mpaActions.requestPayoutError));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getMyPartnerAccountDetails$(): Promise<Partial<DiscountCode>> {
    const promise = lastValueFrom(
      this.actions.pipe(ofType(mpaActions.getMyPartnerAccountDetailsSuccess)).pipe(
        take(1),
        map(({ discountCode }) => discountCode),
        takeUntil(this.getMyPartnerAccountDetailsError$)
      )
    );

    this.store.dispatch(mpaActions.getMyPartnerAccountDetails());
    return promise;
  }

  public getMyPointsSum$(): Promise<SumOfPartnerPoints> {
    const promise = lastValueFrom(
      this.actions.pipe(ofType(mpaActions.getMyPointsSumSuccess)).pipe(
        take(1),
        map(({ sumOfPoints }) => sumOfPoints),
        takeUntil(this.getMyPointsSumError$)
      )
    );

    this.store.dispatch(mpaActions.getMyPointsSum());
    return promise;
  }

  public requestPayout(opts: RequestPayoutOpts): void {
    this.store.dispatch(mpaActions.requestPayout({ opts }));
  }
  public isPayoutAvailable(): void {
    this.store.dispatch(mpaActions.isPayoutAvailable());
  }
  public getMyPayoutsHistory(): void {
    this.store.dispatch(mpaActions.getMyPayoutsHistory());
  }
}
