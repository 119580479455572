import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { filter, map, tap } from 'rxjs/operators';

import {
  GetEmployeesPayload,
  GetForeignersPayload,
  GetUsersPayload,
  ReassignForeignersOpts,
  Role,
  SelfRegisterOpts,
  User,
} from '@interfaces';
import { Actions, ofType } from '@ngrx/effects';
import { AppState } from '@state';
import * as usersActions from './users.actions';
import * as usersSelectors from './users.selectors';

@Injectable()
export class UsersFacade {
  public loading$ = this.store.select(usersSelectors.selectLoading);
  public errorMessage$ = this.store.select(usersSelectors.selectErrorMessage);
  public users$ = this.store.select(usersSelectors.selectLoadedUsers);
  public loadedUser$ = this.store.select(usersSelectors.selectLoadedUser);
  public pagination$ = this.store.select(usersSelectors.selectLoadedPagination);

  public loadingEmployeeTypes$ = this.store.select(usersSelectors.selectLoadingEmployeeTypes);
  public employeeTypes$ = this.store.select(usersSelectors.selectEmployeeTypes);

  public saveSuccess$ = this.actions.pipe(
    ofType(usersActions.updateUserSuccess, usersActions.createUserSuccess),
    map((result: { updatedUser?: User; createdUser?: User }) => result.updatedUser || result.createdUser)
  );

  public saveError$ = this.actions.pipe(
    ofType(usersActions.updateUserError, usersActions.createUserError),
    map((error: { errorMessage: string }) => error.errorMessage)
  );

  public resendSuccess$ = this.actions.pipe(ofType(usersActions.resendActivationSuccess));
  public removeUserSuccess$ = this.actions.pipe(ofType(usersActions.removeUserSuccess));
  public selfRegisterSuccess$ = this.actions.pipe(ofType(usersActions.selfRegisterSuccess));

  public reassignInProgress$ = this.store.select(usersSelectors.selectReassignInProgress);
  public reassignForeignersSuccess$ = this.actions.pipe(ofType(usersActions.reassignForeignersSuccess));
  public reassignForeignersError$ = this.actions.pipe(ofType(usersActions.reassignForeignersError));

  constructor(
    private store: Store<AppState>,
    private actions: Actions
  ) {}

  public getForeigners(payload: GetForeignersPayload): void {
    this.store.dispatch(usersActions.getForeigners({ payload }));
  }

  public getEmployees(payload: GetEmployeesPayload): void {
    this.store.dispatch(usersActions.getEmployees({ payload }));
  }

  public getManagers(payload: GetUsersPayload): void {
    this.store.dispatch(usersActions.getManagers({ payload }));
  }

  public getCompanyEmployees(payload: GetUsersPayload): void {
    this.store.dispatch(usersActions.getCompanyEmployees({ payload }));
  }

  public getUsers(payload: GetUsersPayload): void {
    this.store.dispatch(usersActions.getUsers({ payload }));
  }

  public fetchUser(userId: string): void {
    this.store.dispatch(usersActions.getUser({ userId }));
  }

  public createForeigner(payload: Partial<User>): void {
    this.store.dispatch(usersActions.createForeigner({ payload }));
  }

  public createEmployee(payload: Partial<User>): void {
    this.store.dispatch(usersActions.createEmployee({ payload }));
  }

  public createManager(payload: Partial<User>): void {
    this.store.dispatch(usersActions.createManager({ payload }));
  }

  public updateForeigner(payload: Partial<User>, userId: string): void {
    this.store.dispatch(usersActions.updateForeigner({ payload, userId }));
  }

  public updateEmployee(payload: Partial<User>, userId: string): void {
    this.store.dispatch(usersActions.updateEmployee({ payload, userId }));
  }

  public updateManager(payload: Partial<User>, userId: string): void {
    this.store.dispatch(usersActions.updateManager({ payload, userId }));
  }

  public removeForeigner(userId: string): void {
    this.store.dispatch(usersActions.removeForeigner({ userId }));
  }

  public restoreForeigner(userId: string): void {
    this.store.dispatch(usersActions.restoreForeigner({ foreignerId: userId }));
  }

  public restoreUser(userId: string): void {
    this.store.dispatch(usersActions.restoreUser({ userId }));
  }

  public removeEmployee(userId: string): void {
    this.store.dispatch(usersActions.removeEmployee({ userId }));
  }

  public removeManager(userId: string): void {
    this.store.dispatch(usersActions.removeManager({ userId }));
  }

  // This one will always return User (because of filter operator)
  // but fkin typescript and its strict null checks are the worst
  public getUser$(userId: string): Observable<User | null> {
    return this.store.select(usersSelectors.selectLoadedUser).pipe(
      tap(user => {
        if (!user || user.id !== userId) {
          this.fetchUser(userId);
        }
      }),
      filter(user => {
        return !(user === null || user.id !== userId);
      })
    );
  }

  public getEmployeeTypes(): void {
    this.store.dispatch(usersActions.getEmployeeTypes());
  }

  public getEmployeeTypes$(): Observable<Role[] | null> {
    return this.store.select(usersSelectors.selectEmployeeTypes).pipe(
      tap(employeeTypes => {
        if (employeeTypes === null) {
          this.getEmployeeTypes();
        }
      }),
      filter(employeeTypes => employeeTypes !== null)
    );
  }

  public getAllRoles(): void {
    this.store.dispatch(usersActions.getAllRoles());
  }

  public getAllRoles$(): Observable<Role[] | null> {
    return this.store.select(usersSelectors.selectAllRoles).pipe(
      tap(allRoles => {
        if (allRoles === null) {
          this.getAllRoles();
        }
      }),
      filter(employeeTypes => employeeTypes !== null)
    );
  }

  public resendEmployeeActivation(email: string, userId: string): void {
    this.store.dispatch(usersActions.resendEmployeeActivation({ email, userId }));
  }

  public resendForeignerActivation(email: string, userId: string): void {
    this.store.dispatch(usersActions.resendForeignerActivation({ email, userId }));
  }

  public resendManagerActivation(email: string, userId: string): void {
    this.store.dispatch(usersActions.resendManagerActivation({ email, userId }));
  }

  public selfRegister(opts: SelfRegisterOpts): void {
    this.store.dispatch(usersActions.selfRegister({ opts }));
  }
  public reassignForeigners(opts: ReassignForeignersOpts): void {
    this.store.dispatch(usersActions.reassignForeigners({ opts }));
  }
}
