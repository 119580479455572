<div *ngIf="loadingView; else ViewLoaded" class="loading-view">
  <mat-spinner [diameter]="80" [strokeWidth]="5"></mat-spinner>
</div>

<ng-template #ViewLoaded>
  <div *ngIf="!partnerCode?.code; else PartnerCodeExists" class="no-code-generated-view">
    <h1 [innerHTML]="'PARTNER_ACCOUNT.NO_PARTNER_CODE_GENERATED' | translate"></h1>
  </div>
</ng-template>

<ng-template #PartnerCodeExists>
  <div class="code-header">
    <h1 class="slogan" [innerHTML]="'PARTNER_ACCOUNT.STATIC_SLOGAN' | translate"></h1>
    <h3 class="code-label" [innerHTML]="'PARTNER_ACCOUNT.YOUR_DISCOUNT_CODE' | translate"></h3>
    <div class="code-block">
      <h1 class="code">{{ partnerCode.code }}</h1>
      <button
        [disabled]="disablingLoading$ | async"
        (click)="copyToClipboard(partnerCode.code)"
        nz-button
        nzType="default"
        nzSize="large"
        nzShape="circle">
        <span nz-icon nzType="copy" nzTheme="outline"></span>
      </button>
    </div>
    <h1 class="slogan" [innerHTML]="partnerCode.customTerms_slogan[userLang]"></h1>
  </div>

  <div class="points-wrapper" *ngIf="sumOfPartnerPoints">
    <h3 class="points-label" [innerHTML]="'PARTNER_ACCOUNT.YOUR_POINTS' | translate"></h3>
    <div class="points">
      <div
        #circle1
        class="points-circle in-verification"
        nz-popover
        nzPopoverOverlayClassName="partner-points-circle-popover pending-verification-points"
        [nzPopoverTitle]="PointsCirclePopoverTitle"
        [nzPopoverContent]="PointsCirclePopoverContent"
        [nzPopoverMouseEnterDelay]="0.2"
        (mouseenter)="onCategoryHover(PARTNER_POINTS_STATUS.PENDING_VERIFICATION)">
        {{ sumOfPartnerPoints.PENDING_VERIFICATION }}
      </div>
      <div
        #circle2
        class="points-circle granted"
        nz-popover
        nzPopoverOverlayClassName="partner-points-circle-popover granted-points"
        [nzPopoverTitle]="PointsCirclePopoverTitle"
        [nzPopoverContent]="PointsCirclePopoverContent"
        [nzPopoverMouseEnterDelay]="0.2"
        (mouseenter)="onCategoryHover(PARTNER_POINTS_STATUS.GRANTED)">
        {{ sumOfPartnerPoints.GRANTED }}
      </div>
      <div
        #circle3
        class="points-circle paid-out"
        nz-popover
        nzPopoverOverlayClassName="partner-points-circle-popover paid-out-points"
        [nzPopoverTitle]="PointsCirclePopoverTitle"
        [nzPopoverContent]="PointsCirclePopoverContent"
        [nzPopoverMouseEnterDelay]="0.2"
        (mouseenter)="onCategoryHover(PARTNER_POINTS_STATUS.PAID_OUT)">
        {{ sumOfPartnerPoints.PAID_OUT }}
      </div>
    </div>
  </div>

  <div class="payout-pending-wrapper" *ngIf="sumOfPartnerPoints?.PENDING_PAYOUT > 0">
    <div
      class="pending-payout-circle"
      nz-popover
      nzPopoverOverlayClassName="partner-points-circle-popover paid-out-points"
      [nzPopoverTitle]="PayoutPendingPopoverTitle"
      [nzPopoverContent]="PayoutPendingPopoverContent"
      [nzPopoverMouseEnterDelay]="0.2">
      {{ sumOfPartnerPoints.PENDING_PAYOUT }}
    </div>
  </div>

  <div class="pay-out-button-wrapper" *ngIf="sumOfPartnerPoints?.GRANTED > 0">
    <button
      (click)="openPayoutRequestModal()"
      nz-button
      nzType="primary"
      [nzSize]="'large'"
      nzShape="round"
      nz-tooltip
      [nzTooltipMouseEnterDelay]="0.2"
      [nzTooltipTitle]="'PARTNER_ACCOUNT.YOU_CANT_PAYOUT_BECAUSE_ALREADY_PAIDOUT_THIS_MONTH' | translate"
      [nzTooltipTrigger]="(payoutAvailable$ | async) === false ? 'hover' : null"
      [disabled]="(payoutAvailable$ | async) === false">
      {{ 'PARTNER_ACCOUNT.PAY_OUT_BONUS' | translate }}
    </button>
  </div>

  <div class="actions-list-wrapper">
    <button (click)="instructionsDrawerVisible = true" nz-button nzType="default" nzBlock>
      {{ 'PARTNER_ACCOUNT.HOW_DOES_THIS_WORK' | translate }}
    </button>
    <button (click)="codeUsageDrawerVisible = true" nz-button nzType="default" nzBlock>
      {{ 'PARTNER_ACCOUNT.HISTORY_OF_CODE_USAGE' | translate }}
    </button>
    <button (click)="openPointsHistoryDrawer()" nz-button nzType="default" nzBlock>
      {{ 'PARTNER_ACCOUNT.HISTORY_OF_PAY_OUTS' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #PointsCirclePopoverTitle>
  <div style="text-align: center">
    <span class="popover-title category-{{ hoveredOnSumCategory.categoryName }}">
      {{ 'PARTNER_ACCOUNT.POINTS_STATUS.' + hoveredOnSumCategory.categoryName | translate }}
    </span>
  </div>
</ng-template>
<ng-template #PointsCirclePopoverContent>
  <div class="popover-content category-{{ hoveredOnSumCategory.categoryName }}">
    <span [innerHTML]="'PARTNER_ACCOUNT.POINTS_STATUS_EXPLANATION.' + hoveredOnSumCategory.categoryName | translate">
    </span>
  </div>
</ng-template>

<ng-template #PayoutPendingPopoverTitle>
  <div style="text-align: center">
    <span class="popover-title category-PENDING_PAY_OUT">
      {{ 'PARTNER_ACCOUNT.PAY_OUT_PENDING_TITLE' | translate }}
    </span>
  </div>
</ng-template>
<ng-template #PayoutPendingPopoverContent>
  <div class="payout-pending-popover-content">
    <span [innerHTML]="'PARTNER_ACCOUNT.PAY_OUT_PENDING' | translate: { points: sumOfPartnerPoints?.PENDING_PAYOUT }">
    </span>
  </div>
</ng-template>

<ng-container *ngIf="partnerCode?.code && sumOfPartnerPoints">
  <nz-drawer
    [nzClosable]="true"
    [nzWidth]="isDesktop ? '440px' : '99vw'"
    [nzPlacement]="'left'"
    [nzVisible]="instructionsDrawerVisible"
    nzWrapClassName="my-partner-account-drawer"
    [nzTitle]="'PARTNER_ACCOUNT.HOW_DOES_THIS_WORK' | translate"
    (nzOnClose)="instructionsDrawerVisible = false">
    <ol *nzDrawerContent class="instructions-list-in-drawer">
      <li [innerHTML]="'PARTNER_ACCOUNT.INSTRUCTION_STEP_1' | translate: { code: partnerCode.code }"></li>
      <li [innerHTML]="'PARTNER_ACCOUNT.INSTRUCTION_STEP_2' | translate"></li>
      <li [innerHTML]="'PARTNER_ACCOUNT.INSTRUCTION_STEP_3' | translate"></li>
      <!-- <li [innerHTML]="'PARTNER_ACCOUNT.INSTRUCTION_STEP_4' | translate"></li> -->
    </ol>
  </nz-drawer>

  <nz-drawer
    [nzClosable]="true"
    [nzWidth]="isDesktop ? '440px' : '99vw'"
    [nzPlacement]="'left'"
    [nzVisible]="codeUsageDrawerVisible"
    nzWrapClassName="my-partner-account-drawer"
    [nzTitle]="'PARTNER_ACCOUNT.HISTORY_OF_CODE_USAGE' | translate"
    [nzBodyStyle]="{ padding: '0 5px 24px 5px' }"
    (nzOnClose)="codeUsageDrawerVisible = false">
    <nz-table
      *nzDrawerContent
      class="code-usage-table"
      #codeUsageTable
      [nzData]="partnerCode.usedInPurchasesConfirmed"
      [nzShowPagination]="false">
      <thead>
        <tr>
          <th>{{ 'PARTNER_ACCOUNT.CODE_USAGE_TABLE.BUYER' | translate }}</th>
          <th *ngIf="isDesktop">{{ 'PARTNER_ACCOUNT.CODE_USAGE_TABLE.DATE' | translate }}</th>
          <th>{{ 'PARTNER_ACCOUNT.CODE_USAGE_TABLE.POINTS' | translate }}</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let item of codeUsageTable.data">
          <td class="name-cell">{{ item.user.name | truncate: 30 : false }}</td>
          <td *ngIf="isDesktop" class="date-cell">{{ item.updatedAt | date: 'dd-MM-yyyy' }}</td>
          <td class="points-cell">
            <div class="points-in-table reverted" *ngIf="!item.partnerPointsAdded?.length">0</div>
            <div
              *ngFor="let points of item.partnerPointsAdded"
              class="points-in-table"
              [class.pending-verification]="points.status === PARTNER_POINTS_STATUS.PENDING_VERIFICATION"
              [class.granted]="points.status === PARTNER_POINTS_STATUS.GRANTED"
              [class.pending-payout]="points.status === PARTNER_POINTS_STATUS.PENDING_PAYOUT"
              [class.paid-out]="points.status === PARTNER_POINTS_STATUS.PAID_OUT"
              [class.reverted]="points.status === PARTNER_POINTS_STATUS.REVOKED">
              {{ points.quantity }}
            </div>
          </td>
        </tr>
      </tbody>
    </nz-table>
  </nz-drawer>

  <nz-drawer
    [nzClosable]="true"
    [nzWidth]="isDesktop ? '440px' : '99vw'"
    [nzPlacement]="'left'"
    [nzVisible]="payoutsHistoryDrawerVisible"
    [nzTitle]="'PARTNER_ACCOUNT.PAYOUTS_HISTORY' | translate"
    nzWrapClassName="my-partner-account-drawer"
    [nzBodyStyle]="{ padding: '0 5px 24px 5px' }"
    (nzOnClose)="payoutsHistoryDrawerVisible = false">
    <ng-container *nzDrawerContent>
      <div class="payouts-history-loading-wrapper" *ngIf="payoutsHistoryLoading">
        <mat-spinner [diameter]="75" [strokeWidth]="4"> </mat-spinner>
      </div>

      <nz-table
        *ngIf="!payoutsHistoryLoading && (payoutsHistory$ | async) as payoutsHistory"
        class="payouts-history-table"
        #payoutsHistoryTable
        [nzData]="payoutsHistory"
        [nzShowPagination]="false">
        <thead>
          <tr>
            <th>{{ 'PARTNER_ACCOUNT.PAYOUTS_HISTORY_TABLE.REQUESTED_AT' | translate }}</th>
            <th>{{ 'PARTNER_ACCOUNT.PAYOUTS_HISTORY_TABLE.POINTS_IN_PAYOUT' | translate }}</th>
            <th>{{ 'PARTNER_ACCOUNT.PAYOUTS_HISTORY_TABLE.STATUS' | translate }}</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let item of payoutsHistoryTable.data">
            <td class="date-cell">{{ item.requestedAt | date: 'dd-MM-yyyy' }}</td>
            <td class="points-cell">{{ item.totalPointsInPayout }}</td>
            <td class="name-cell">{{ 'PARTNER_ACCOUNT.PAYOUT_STATUS.' + item.status | translate }}</td>
          </tr>
        </tbody>
      </nz-table>
    </ng-container>
  </nz-drawer>
</ng-container>
